/* FOR BOX-RECORDS PAGE */
.box_records{
  background: #031030;
    /* background: rgba(97, 95, 95, 0.3); */
    border-radius: 30px;
    height: 310px;
    margin-bottom: 20px;
    width: 250px;
}

.boxRecods-image{
    z-index: 1;
}

.box_records .image-frame{
    text-align: center;
}

.box_records .image-frame .profileImage{
    border-radius: 140px;
    width: 100px;
    margin: -35px auto 0;
    height: 100px;
}

.pointer{
    cursor: pointer;
}

.lds-dual-ring{
    width: unset!important;
    height: unset !important;
}

a{
    text-decoration: none!important;
}

.quotes{
    padding-left: 20px;
    padding-right: 20px;
}

.boxRecods-image{
    border-radius: 100px!important;
}

.boxes{
    padding-bottom: 70px!important;
}

.deletedBox{
  padding-bottom: 20px!important;
}

.box-records-row {
    display: flex;
    flex-direction: row;
    column-gap: 30px;
    flex-wrap: wrap;
    justify-content: center;
  }

.publicBox_userName{
    font-family: 'Avenir Next Demi';
    font-weight: 600;
    font-size: 15px;
    line-height: 25px;
    text-align: center;
    letter-spacing: 0.315324px;
}

.publicBox_boxName{
    font-family: 'Avenir Next Demi';
    font-weight: 600;
    font-size: 15px;
    line-height: 25px;
    text-align: center;
    letter-spacing: 0.315324px;
}

.publicBox_userDetails{
    width: 100%;
    height: 90px !important;
    margin: 0 auto 70px !important;
    position: relative;
    top: -70px;
}

#searchBox::placeholder {
    color: #a7a6a6;
    opacity: 1;
}

 /* Foundation Page */

 .foundationDetailColumn{
        
        max-width:200px;
        overflow: hidden;
        text-overflow: ellipsis;
 }

input.editBox::placeholder,textarea.editBox::placeholder{
  color: #a7a6a6;
    opacity: 1;
}

.foundationPage{
  background: linear-gradient(268.67deg, rgba(26, 32, 46, 0.7) 1.01%, rgba(26, 32, 46, 0.679) 78.02%, rgba(26, 32, 46, 0.455) 98.78%);
  border-radius: 20px;
  padding-top: 30px;
  padding-bottom: 30px;
}

.foundationList{
  background: #031030;
  display: flex;
  border-radius: 35px;
  padding: 15px 43px 25px;
  margin-bottom: 20px;
  border: 0.5px solid rgb(109, 109, 109);
  align-items: center;
}



.foundationList .contentImg {
  width: 220px;
  /* height: 150px; */
  border-radius: 50px;
  overflow: hidden;
}

.foundationList .contentDesc {
  /* width: calc(100% - 220px - 24px); */
}
.foundationList .contentDesc .desc-data{
  margin-bottom: 15px;
}

.foundationList .contentImg {
  margin-right: 24px;
}

@media (max-width: 767px) {
  .foundationList{
    flex-wrap: wrap;
    justify-content: center;
    text-align: center;
    flex-direction: column;
    align-items: center;
  }
  .foundationList .contentImg {
    width: 190px;
    margin-right: 0;
  }
}

.foundationList h4 {
  font-family: 'Avenir Next Demi';
  font-weight: 600;
  font-size: 20px;
  line-height: 25px;
  letter-spacing: 0.315324px;
  color: #E79E1F;
  margin: 10px 0 0;
}

.foundationList p {
  font-family: 'Avenir Next Medium';
  font-weight: 500;
  font-size: 18px;
  line-height: 25px;
  letter-spacing: 0.315324px;
  color: #FFFFFF;
}

.foundationList .callus img {
  margin-right: 7px;
}

.foundationList .callus a,
.foundationList .callus span {
  font-family: 'Avenir Next Regular';
  font-weight: 400;
  font-size: 15px;
  line-height: 25px;
  letter-spacing: 0.315324px;
  color: #FFFFFF;
  text-decoration: none;
}

.foundationList h4 a {
  color: #E79E1F;
}

/* ------------------------------------------------------------------------------------------------------------------- */

/* Scroll to top  */
.scroll-to-top{
    position: relative;
  }
  .icon-position{
    position: fixed;
    bottom: 40px;
    right: 25px;
    z-index: 20;
  }
  .icon-style{
    background-color: #ff8401;
    border: 2px solid #fff;
    text-align: center;
    border-radius: 50%;
    height: 35px;
    width: 35px;
    color: #fff;
    cursor: pointer;
    animation: movebtn 3s ease-in-out infinite;
    transition: all .5s ease-in-out;
  }
  .icon-style:hover{
    animation: none;
    background: #fff;
    color: #E79E1F;
    border: 2px solid #E79E1F;
  }
  @keyframes movebtn {
    0%{
      transform: translateY(0px);
    }
    25%{
      transform: translateY(20px);
    }
    50%{
      transform: translateY(0px);
    }
    75%{
      transform: translateY(-20px);
    }
    100%{
      transform: translateY(0px);
    }
  }
/* Scroll to top */

/* ------------------------------------------------------------------------------------------------------------------- */

/* EDIT BOX PAGE STARTS HERE */
  .carousel_img{
    overflow: hidden;
    border-radius: 10%!important;
  }

  .carousel_img img{
    transition: 0.5s all ease-in-out;
    border-radius: 10%;
  }

  .carousel_img:hover img{
    transform: scale(1.5);
  }

  .searchIcon_box{
    background: #E79E1F!important;
    border: 1px solid #E79E1F!important;
  }

  .searchIcon_box:hover{
    background: rgba(62, 62, 62, 0.9)!important;
    color: #E79E1F!important;
    border: 1px solid rgba(62, 62, 62, 0.9)!important;
  }
/* EDIT BOX PAGE ENDS HERE  */

/* ------------------------------------------------------------------------------------------------------------------- */

/* PUBLIC BOX DETAIL PAGE STARTS*/
.read_more_or_less{
  display: block;
  font-size: 12px;
  max-width: 100px;
}

.read_more_or_less:hover{
  color: #E79E1F!important;
}
/* PUBLIC BOX DETAIL PAGE ENDS*/

/* ------------------------------------------------------------------------------------------------------------------- */

/* CREATED BOX PAGE CSS FOR RECIPIENT DETAILS AND BOX DESCRIPTION STARTS HERE */
.recipient-section {
  color: #fff;
}
.recipient-section h2.title,
.description-section h2.title {
  font-size: 18px;
  margin-bottom: 5px;
  color: #fff
}
.recipient-section .dtl-list-tbl {
  border: 2px solid #fff;
  border-radius: 12px;
  overflow-x: auto;
  /* padding: 10px 10px; */
  margin-bottom: 10px;
}
.recipient-section .dtl-list-tbl .listing-head {
  background: rgba(256, 256, 256, 0.7);
  color: #000;
  overflow: hidden;
  display: flex;
  padding: 5px 10px;
}
.recipient-section .dtl-list-tbl .listing-head .lbl {
  min-width: 25%;
  font-size: 15px;
  font-weight: 600;
}
.recipient-section .dtl-list-tbl .listing-head .lbl:nth-of-type(1) {
  min-width: 10%;
}
.recipient-section .dtl-list-tbl .list-row {
  display: flex;
  overflow-x: hidden;
  padding: 10px 10px;
}
.recipient-section .dtl-list-tbl .list-row .lbl {
  min-width: 25%;
}
.recipient-section .dtl-list-tbl .list-row .lbl:nth-of-type(1){
  min-width: 10%;
}
.description-section {
  padding-top: 10px;
}
.description-section .desc-data {
  height:100px;
  color: #fff;
  overflow-y: auto;
}
.description-section .desc-data p {
  margin-top: 0;
}


.recipient-editBtn{
  background: rgba(62, 62, 62, 0.9)!important;
  color: #E79E1F!important;
  border: 0!important;
  border-radius: 10px!important;
  /* height: 30px!important; */
  width: 65px;
}

.recipient-editBtn:hover{
  color: #FFFFFF!important;
  background: rgba(231, 158, 31, 0.8)!important;
}

.recipient-saveBtn{
  background: rgba(89, 159, 80, 0.9)!important;
  color: #FFFFFF!important;
  border: 0!important;
  border-radius: 10px!important;
  /* height: 30px!important; */
  width: 65px;
}

.recipient-saveBtn:hover{
  color: #FFFFFF!important;
  background: rgba(25, 102, 4, 0.8)!important;
}

#emailOTP::placeholder,#mobileOTP::placeholder,#UIC::placeholder{
  color: #a7a6a6;
  opacity: .5;
}
/* CREATED BOX PAGE CSS FOR RECIPIENT DETAILS AND BOX DESCRIPTION ENDS HERE */

/* ------------------------------------------------------------------------------------------------------------------- */

/* CREATE BOX PAGE CSS STARTS HERE*/

#boxName::placeholder, #boxDescription::placeholder,#searchByuserName::placeholder,#Fname::placeholder,#Lname::placeholder,#emailAddr::placeholder {
  color: #a7a6a6;
  opacity: 1;
}

.imgDiv {
  position: relative;
}
.text-on-image {
 font-size: 12px;
 position: absolute;
 right: 0%;
 left: 90.3%;
 top: 222%;
}

.searchDropdown{
  background-color: #fff;
  display: flex;
  flex-direction: column;
  border: 1px solid grey;
}

.searchDropdown:empty{
  border: none;
}

.dropdown .dropdown-menu .dropdown-item {
  color: #E79E1F!important;
}

.dropdown .dropdown-menu .dropdown-item:hover {
  color: #FFFFFF!important;
  background: rgba(231, 158, 31, 0.8)!important;
}

.linkClass{
  color: #E79E1F!important;
}

.linkClass:hover{
  color: #FFFFFF!important;
}

.recipientList{
  background: rgba(97, 95, 95, 0.3);
  border-radius: 10px;
}

/* ------------------------------------- */
.recipientScrollbar {
  padding: 0;
  /* display: flex; */
  /* flex-direction: column; */
  max-height: 250px;
  overflow: hidden;
  overflow-y: auto;
  scrollbar-color: transparent transparent;
  scrollbar-width: thin;
}
.recipientScrollbar:hover{
  scrollbar-color: #888 #f1f1f1;
}

.recipientScrollbar::-webkit-scrollbar {
  width: 3px;
  display: none;
}
.recipientScrollbar:hover::-webkit-scrollbar {
  display: block;
}
.recipientScrollbar::-webkit-scrollbar-track {
  background: #f1f1f1; 
}

.recipientScrollbar::-webkit-scrollbar-thumb {
  background: #888; 
}

.recipientScrollbar::-webkit-scrollbar-thumb:hover {
  background: #555; 
}

/* ------------------------------------- */

/* CREATE BOX PAGE CSS ENDS  HERE */

/* FOR SIGNUP PAGE STARTS HERE */
#fullName::placeholder,#emailSignup::placeholder,#passwordSignup::placeholder,#confirmPasswordSignup::placeholder,#mobileSignup::placeholder{
  color: #a7a6a6;
  opacity: 0.5;
}
/* FOR SIGNUP PAGE   ENDS HERE */

/* FOR USER AUTHORISATION */
#authorisedOTP::placeholder{
  color: #a7a6a6;
  opacity: 0.5;
}

/* LOGIN PAGE STARTS HERE*/
#login_email::placeholder,#login_password::placeholder{
  color: #a7a6a6;
  opacity: 0.5;
}
/* LOGIN PAGE ENDS HERE  */

/* EDiT PROFILE PAGE STARTS HERE */
#editProfile_FName::placeholder,
#editProfile_LName::placeholder,
#editProfile_IdentityNo::placeholder,
#editProfile_lifesWork::placeholder,
#editProfile_quote::placeholder,
#editProfile_whatILivedFor::placeholder,
#editProfile_kinName::placeholder,
#editProfile_kinEmail::placeholder,
#editProfile_kinPhoneNumber::placeholder,
#editProfile_kinNameOpt::placeholder,
#editProfile_kinEmailOpt::placeholder,
#editProfile_kinPhoneNumberOpt::placeholder{
  color: #a7a6a6;
  opacity: 1;
}
/* EDiT PROFILE PAGE ENDS HERE   */

video {
  object-fit: cover;
}

/* People Page*/
.table-background {
  padding-bottom: 50px;
  background: rgba(97, 95, 95, 0.3);
  /* border-radius: 15px; */
}

.deletedBoxSize{
  height:170px !important;
  width: 17% !important;
  border-radius: 15px !important;
}

.deletedBoxName{
  margin-top: 0px!important;
}

/* recycle bin */
table {
  min-width: 650;
  border-collapse: 'separate';
  border-spacing: '0px 4px';
}


.box-records-row .box_records{
  width: 290px;
}
.box-records-row .box_records .btm-content-frame{
  height: 74%;
}
.box-records-row .box_records .quotes{
  max-height: 53%;
}
.box-records-row .box_records .quotes span {
    height: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 5;
    display: -webkit-box;
    -webkit-box-orient: vertical;
}

/* CONTACT US PAGE CSS */
.contactBg{
  /* background: linear-gradient(268.67deg, rgba(26, 32, 46, 0.7) 1.01%, rgba(26, 32, 46, 0.679) 78.02%, rgba(26, 32, 46, 0.455) 98.78%); */
  background: #020620;
  padding: 40px 40px 50px;
  border-radius: 15px;
}
.map{
  height: 250px;
  margin-top: 30px;
}
.contactUs label{
  font-family: 'Avenir Next Medium';
  font-weight: 500;
  font-size: 18px;
  line-height: 25px;
  letter-spacing: 0.315324px;
  color: #E79E1F;
  margin-bottom: 0px;
}
.contactUs .form-control{
  background-color: #031030 !important;
  border: 1px solid #123ea9 !important;
  font-family: "Avenir Next Demi";
  font-size: 20px;
  line-height: 50px;
  color: #ffffff !important;
  height: 50px;
  margin-bottom: 7px;
  padding: 0 1rem;
}

.contactUs .form-control::placeholder
{
  color: #fff !important;
  opacity: 1 !important;
  
}
.contactUs textarea.form-control{
  height: 120px;
  line-height: 26px;
  padding: 0.8rem;
}
.contactUs .formRow{
  margin-bottom: 20px;
}
/* CONTACT US PAGE CSS */

button:active{
  background-color: #E79E1F;
  border-color: #E79E1F;
}

/* forgot-password */
#forgotPassword_email::placeholder,
#forgotPassword_OTP::placeholder,
#forgotPassword_newPassword::placeholder,
#forgotPassword_confirmPassword::placeholder{
  color: #a7a6a6;
  opacity: 1; 
}

/* signup page */
.inputCheck label{
  font-weight: 800;
}

button.payfastBtn:active {
  background-color: #fff!important;
  border-color: #fff!important;
}

/* --------------------------------- */
.relationShip .form-select{
  color: #a7a6a6!important;
  font-size: 18px!important;
}

.relationShip .intRel--has-value,
.relationShip .extRel--has-value{
  color: #000!important;
}

.relationShip .form-control{
  font-size: 18px!important;
}

.relationShip .form-select option:not(:checked){ 
 color: #000000!important;
}

.relationShip input[type="date"]::-webkit-datetime-edit-text,
.relationShip input[type="date"]::-webkit-datetime-edit-month-field,
.relationShip input[type="date"]::-webkit-datetime-edit-day-field,
.relationShip input[type="date"]::-webkit-datetime-edit-year-field {
  color: #a7a6a6;
}

.relationShip input[type="date"].date-input--has-value::-webkit-datetime-edit-text,
.relationShip input[type="date"].date-input--has-value::-webkit-datetime-edit-month-field,
.relationShip input[type="date"].date-input--has-value::-webkit-datetime-edit-day-field,
.relationShip input[type="date"].date-input--has-value::-webkit-datetime-edit-year-field {
  color: #000;
}

.relationShip input[type="date"].date-input--has-value-int::-webkit-datetime-edit-text,
.relationShip input[type="date"].date-input--has-value-int::-webkit-datetime-edit-month-field,
.relationShip input[type="date"].date-input--has-value-int::-webkit-datetime-edit-day-field,
.relationShip input[type="date"].date-input--has-value-int::-webkit-datetime-edit-year-field {
  color: lightgrey;
}
/* --------------------------------- */

/* FREQUENTLY ASKED QUESTIONS */
.faqMainContent{
  background: linear-gradient(268.67deg, rgba(26, 32, 46, 0.7) 1.01%, rgba(26, 32, 46, 0.679) 78.02%, rgba(26, 32, 46, 0.455) 98.78%);
  padding: 30px 30px 30px 30px;
  border-radius: 20px;
}
.faqMainContent p{
  margin-bottom: 40px;
}
.faqMainContent .heading{
  text-align: center;
}

/* FREQUENTLY ASKED QUESTIONS */

/* CHANGE PASSWORD */
#changePassword_oldPassword::placeholder,
#changePassword_newPassword::placeholder,
#changePassword_confirmPassword::placeholder
{
  color: #a7a6a6;
  opacity: 0.5;
}
/* CHANGE PASSWORD */

.publicMessage img{
  display: inline!important;
  margin: 0 5px!important;
  vertical-align: 0px!important;
}

.paragraph{
  display: flex;
  justify-content: space-between;
}

.inputCheck_editProfile{
  display: flex;
  align-items: flex-start;
}
.max-width-50{
  max-width: 50%;
}
.max-width-60{
  max-width: 60%;
}