.admin_header{
    background: #17191C;
    padding: 22px 32px !important;
    position: sticky !important;
    margin-bottom: 30px;
}
.admin_header .navLeft {
    display: flex;
    margin-left: auto;
}
.adminInnerpage{
    background: url('../images/background.jpg') no-repeat center !important;
    background-size: cover !important;
    min-height: 100vh;
}
.noHover{
    pointer-events: none !important;
    width: 350px !important;
    background: #000000 !important;
}

.admin_header .profile .dropdown .dropdown-toggle{
    background: transparent;
    padding: 0;
    width: auto;
}
.admin_header .profile .dropdown .dropdown-menu{
    width: 200px;
    padding: 20px 15px 15px;
}
.admin_header .profile .dropdown .dropdown-toggle:after{
    display: none;
}
.dropdown .dropdown-menu .dropdown-item{
    text-transform: capitalize;
}
.adminInnerpage .leftWrap {
    background: #17191C;
    border-right: 1px solid rgba(97, 95, 95, 0.3);
    padding-top: 5px;
}
.adminInnerpage .leftWrap .navigation li img{
    width: 30px;
    margin-right: 35px;
    margin-left: 17px;
}
.adminInnerpage .leftWrap .navigation li .dropdown .dropdown-toggle{
    background: transparent;
    font-family: 'Avenir Next Demi';
    font-size: 22px;
    line-height: 25px;
    letter-spacing: 0.315324px;
    color: #FFFFFF;
    text-decoration: none;
    padding: 0;
}
.adminInnerpage .leftWrap .navigation li .dropdown .dropdown-menu{
    position: static !important;
    background: transparent;
    transform: none !important;
}
.adminInnerpage .leftWrap .navigation li .dropdown .dropdown-menu ul{
    padding-left: 100px;
}
.adminInnerpage .leftWrap .navigation li .dropdown .dropdown-menu ul li{
    margin: 0 0 15px;
}
.adminInnerpage .leftWrap .navigation li .dropdown .dropdown-menu ul li a{
    font-family: 'Avenir Next Demi';
    font-size: 20px;
    line-height: 25px;
    letter-spacing: 0.315324px;
    padding-left: 20px;
}
.wrapper{
    background: linear-gradient(268.67deg, rgba(26, 32, 46, 0.7) 1.01%, rgba(26, 32, 46, 0.679) 78.02%, rgba(26, 32, 46, 0.455) 98.78%);
    padding: 20px;
    margin: 0 0 90px;
}
.adminRightWrap {
    padding-left: 144px;
    /* min-height: 100vh; */
    display: flex;
    flex-flow: column;
}
.admin_footer{
    background: #17191C;
    padding: 20px 10px;
    color: #ffffff;
    margin-top: auto;
    position: fixed;
    bottom: 0;
    width: 100%;
}
table td,
table th{
    color: #ffffff;
    white-space: nowrap;
}
table th{
    font-weight: 600;
    font-size: 17px;
}
.userpic{
    width: 40px;
    height: 40px;
    border-radius: 50%;
    overflow: hidden;
}
.adminInnerpage .dotMenuBtn {
    top: 110px;
}

@media (max-width: 1600px){
    .adminRightWrap {
        padding-left: 142px;
        padding-right: 20px;
    }
}
@media (min-width: 1400px){
    .adminInnerpage .container, .adminInnerpage .container-lg, .adminInnerpage .container-md, .adminInnerpage .container-sm, .adminInnerpage .container-xl, .adminInnerpage .container-xxl {
        max-width: 90%;
    }
}
@media (max-width: 1199px){
    .admin_header .navLeft {
        width: auto;
        justify-content: flex-end;
    }
}

@media (max-width: 991px){
    .adminRightWrap {
        padding-left: 70px;
        padding-right: 20px;
    }
    .adminInnerpage .leftWrap{
        /* width: 110px; */
    }
    .adminInnerpage .navbar-brand{
        width: 90px;
        display: inline-block;
    }
}
@media (max-width: 767px){  
    .adminInnerpage .leftWrap .navigation{
        padding-left: 0;
        padding-right: 0;
    }
    .adminInnerpage .leftWrap{
        width: 310px;
        transform: translateX(-310px);
    }
    .admin_header{
        padding: 6px 32px 0 !important;
    }
    .adminRightWrap {
        padding-left: 0;
        padding-right: 0;
    }
    .adminInnerpage .leftWrap .navigation li img {
        width: 30px;
        margin-right: 12px;
        margin-left: 4px;
    }
    .adminInnerpage .leftWrap .navigation li .dropdown .dropdown-menu ul {
        padding-left: 48px;
    }
    .adminInnerpage .leftWrap .navigation li .dropdown .dropdown-toggle {
        font-size: 18px;
    }
    .adminInnerpage .leftWrap .navigation li .dropdown .dropdown-menu ul li a{
        font-size: 18px;
    }
}


.adminModal .btn-close{
    top:auto
}

.scrollBar{
    position: relative;
    overflow: auto;
    height: 52vh;
  }
  
  .no-scroll {
    overflow: hidden;
  }

  .scrollbar thead{
    position: sticky;
    top: 0;
  }

  .scrollBar table{
    position: relative;
  }

  body ::-webkit-scrollbar {
    height: 3px;
    width: 3px;
    display: none;
}
 body :hover::-webkit-scrollbar {
    display: block;
}
body ::-webkit-scrollbar-track {
    background: #f1f1f1; 
}

body ::-webkit-scrollbar-thumb {
    background: #888; 
}

body ::-webkit-scrollbar-thumb:hover {
    background: #555; 
}

table tr:last-child td{
    border-bottom: 0;
}

.switch .admin_slider {
    background-color: red;
    bottom: 0;
    cursor: pointer;
    left: 15px;
    position: absolute;
    right: 0;
    top: 0;
    transition: .4s;
}

.switch .admin_slider:before {
    background-color: #fff;
    bottom: 4px;
    content: "";
    height: 12px;
    left: 4px;
    position: absolute;
    transition: .4s;
    width: 12px;
}

.switch input:checked+.admin_slider {
    background-color: green;
}

.switch input:checked+.admin_slider:before {
    transform: translateX(26px);
    background: #ffffff;
}

.switch .admin_slider.round {
    border-radius: 34px;
}

.switch .admin_slider.round:before {
    border-radius: 50%;
}
.editorClassName {
overflow-y: scroll !important;
height: 250px !important;
}

/*new css*/
.adminModal .modal-body img,
.adminInnerpage table td img{
    height: auto;
}