@import url('font.css');

body {
    font-family: 'Avenir Next Medium';
    background:#000000;
}

ul {
    list-style: none;
}

img {
    max-width: 100%;
}

h1 {
    font-family: 'Avenir Next Demi';
    font-weight: 600;
    font-size: 40px;
    line-height: 55px;
    color: rgba(249, 166, 24, 0.83);
    margin-bottom: 13px;
}

h2 {
    font-family: 'Avenir Next Medium';
    font-weight: 500;
    font-size: 40px;
    line-height: 55px;
    color: #F9A618;
}

h3 {
    font-family: 'Avenir Next Demi';
    font-weight: 600;
    font-size: 30px;
    line-height: 40px;
    letter-spacing: 0.315324px;
    color: #FFFFFF;
}
b, strong{
    font-family: 'Avenir Next Demi';
    font-weight: 600;
}
.createdBox{
    /* marginTop: '5px',marginLeft:'50px' */
    margin-top: 15px;
    /* margin-left: 50px; */
    padding-right: 25px;
    text-align: center;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1; /* number of lines to show */
            line-clamp: 1; 
    -webkit-box-orient: vertical;

}

.closeNav {
    background: none !important;
    padding: 0;
    outline: none !important;
    border: 0 !important;
    box-shadow: none !important;
    position: absolute;
    width: 46px;
    top: 30px;
    right: 10px;
    border-radius: 0px 10px 10px 0;
    z-index: 99;
    cursor: pointer;
}

.dotMenuBtn {
    background: #E79E1F;
    width: 55px;
    padding: 5px 0;
    outline: none !important;
    border: 0 !important;
    box-shadow: none !important;
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 20px;
    left: 0;
    border-radius: 0px 10px 10px 0;
    z-index: 99;
}

.dotMenuBtn img {
    width: 37px;
}

.dotMenuBtn:hover,
.dotMenuBtn:focus {
    background: #E79E1F;
}

.flexBox {
    display: flex;
}

.leftWrap {
    padding: 36px 11px 40px;
    width: 121px;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
    /* background: rgba(97, 95, 95, 0.3); */
    background: rgba(11, 19, 32, 0.8);
    transition: 0.5s ease;
    white-space: nowrap;
}

.leftWrap .navbar-brand {
    margin-right: 0;
}

.leftWrap .navigation {
    padding: 50px 10px 0;
    overflow: hidden;
    height: calc(100% - 80px);
}

.leftWrap .navigation ul {
    padding: 0;
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow: hidden;
    overflow-y: auto;
    scrollbar-color: transparent transparent;
    scrollbar-width: thin;
}
.leftWrap .navigation ul:hover{
    scrollbar-color: #888 #f1f1f1;
}

.leftWrap .navigation ul::-webkit-scrollbar {
    width: 3px;
    display: none;
}
.leftWrap .navigation ul:hover::-webkit-scrollbar {
    display: block;
}
.leftWrap .navigation ul::-webkit-scrollbar-track {
    background: #f1f1f1; 
}

.leftWrap .navigation ul::-webkit-scrollbar-thumb {
    background: #888; 
}

.leftWrap .navigation ul::-webkit-scrollbar-thumb:hover {
    background: #555; 
}

.leftWrap .navigation li {
    margin-bottom: 5vmin;
}

.leftWrap .navigation a {
    font-family: 'Avenir Next Demi';
    font-size: 22px;
    line-height: 25px;
    letter-spacing: 0.315324px;
    color: #FFFFFF;
    text-decoration: none;
}

.leftWrap .navigation li a {
    font-family: 'Avenir Next Demi';
    font-size: 22px;
    line-height: 25px;
    letter-spacing: 0.315324px;
    color: #FFFFFF;
    text-decoration: none;
}
.leftWrap .navigation li a:hover,
.leftWrap .navigation li:hover a {
    color: #f9a618
}

.leftWrap .navigation li.addBtn {
    padding: 29px 0 27px 112px;
    background: url('../images/addBtn.png') no-repeat left center;
    background-size: 50px 50px;
    margin-left: 15px;
    cursor: pointer;
}

.leftWrap .navigation li.myboxes {
    padding: 7px 0 7px 112px;
    background: url('../images/myBoxes.png') no-repeat 25px center;
    background-size: 30px 28px;
}

.leftWrap .navigation li.plans {
    padding: 7px 0 7px 112px;
    background: url('../images/plans.png') no-repeat 24px center;
    background-size: 30px 30px;
}

.leftWrap .navigation li.password {
    padding: 7px 0 7px 112px;
    background: url('../images/password.png') no-repeat 20px center;
    background-size: 40px 40px;
}

.leftWrap .navigation li.people {
    padding: 7px 0 7px 112px;
    background: url('../images/people.png') no-repeat 19px center;
    background-size: 40px 40px;
}

.leftWrap .navigation li.recyclebinEmpty {
    padding: 7px 0 7px 112px;
    background: url('../images/recyclebin-empty.png') no-repeat 20px center;
    background-size: 40px 40px;
}

.leftWrap .navigation li.recyclebinFull {
    padding: 7px 0 7px 112px;
    background: url('../images/recyclebin-full.png') no-repeat 20px center;
    background-size: 40px 40px;
}

.leftWrap .navigation li.terms {
    padding: 7px 0 7px 112px;
    background: url('../images/terms.png') no-repeat 26px center;
    background-size: 30px 32px;
}

.leftWrap .navigation li.logout {
    padding: 7px 0 7px 112px;
    background: url('../images/logout.png') no-repeat 22px center;
    background-size: 36px 39px;
    margin: auto 0 0;

}

.leftWrap .navigation li.profile {
    padding: 0 0 0 16px;
    background-size: 30px 32px;
}

.leftWrap .navigation li.profile .profileImg {
    width: 40px;
    height: 40px;
    display: inline-block;
    border-radius: 50%;
    overflow: hidden;
    vertical-align: middle;
    margin-right: 52px;
    margin-left: 3px;
}

.leftWrap .navigation li.profile .profileImg img {
    height: 100%;
    width: 100%;
}

.rightWrap {
    /* padding-left: 144px; */
    padding: 160px 20px 1px 142px !important;
}

.createAccount {
    width: 100%;
    /* height: 100vh; */
    display: flex;
    justify-content: flex-end;
    /* background: url('../images/signupBg.jpg') center; */
    background: url('../images/homeBg1.jpg') center;
    background-repeat: no-repeat;
    background-size: cover;
}

.createAccount .userDetailBlock {
    /* height: 100%; */
    background-color: #020620;
    width: 50%;
    /* background: linear-gradient(268.67deg, #1A202E 1.01%, rgba(26, 32, 46, 0.97) 78.02%, rgba(26, 32, 46, 0.65) 98.78%); */
    border-radius: 80px 0px 0px 0px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.createAccount .formWrap {
    max-width: 100%;
    width: 400px;
    margin: 0 auto;
    padding-top: 30px;
    padding-bottom: 30px;
}

.createAccount .App-logo {
    width: 120px;
}

.createAccount h2 {
    color: #ffffff;
    font-size: 30px;
    line-height: 41px;
    margin-bottom: 30px;
}

.passwordHide {
    position: relative;
}

.passwordHide .hideIcon {
    position: absolute;
    bottom: 5px;
    right: 0;
    background: transparent;
    box-shadow: none !important;
    border: 0 !important;
    outline: none !important;
}

.createAccount form .formRow {
    margin-bottom: 30px;
}

.createAccount form .form-label {
    color: #E79E1F;
    font-size: 18px;
    line-height: 25px;
    margin-bottom: 2px;

}

.createAccount form .forgetPass {
    font-family: 'Avenir Next Regular';
    font-weight: 400;
    font-size: 15px;
    line-height: 25px;
    letter-spacing: 0.315324px;
    text-decoration-line: underline;
    color: #FFFFFF;
}

.createAccount form .form-control {
    font-family: 'Avenir Next Demi';
    font-size: 20px;
    line-height: 25px;
    /* color: #FFFFFF; */
    background-color: #031030 !important;
    border: 1px solid #123ea9 !important;
    color: #fff;
    /* background: transparent; */
    /* border: 0; */
    /* border-radius: 0;
    border-bottom: 3px solid #FFFFFF; */
    outline: none;
    /* box-shadow: none; */
    /* padding: 4px 0; */
    padding:10px;
}

.createAccount form .form-control::placeholder{
    color: #fff !important;
    opacity: 1 !important;
}
.createAccount form .form-control::-ms-input-placeholder{
    color: #fff;
}
.createAccount form .otpInput {
    display: flex;
}

.createAccount form .otpInput .form-control {
    border: 2px solid #FFFFFF;
    margin: 0 5px;
    text-align: center;
    padding: 0.375rem 0.75rem;
}

.createAccount form .form-select {
    font-family: 'Avenir Next Demi';
    font-size: 19px;
    line-height: 25px;
    border: 0;
    border-radius: 0;
    outline: none;
    box-shadow: none;
}

.createAccount form .form-control::placeholder {
    color: #FFFFFF;
    opacity: 1;
}

.createAccount form .inputCheck {
    display: flex;
}

.createAccount form .inputCheck input[type="checkbox"] {
    width: 17px;
    height: 17px;
    border: 1px solid #E79E1F;
    border-radius: 0;
    margin-right: 9px;
}

.createAccount form .inputCheck input[type="checkbox"]:focus {
    box-shadow: none;
}

.createAccount form .inputCheck label {
    color: #ffffff;
    font-size: 15px;
    line-height: 25px;
    font-family: 'Avenir Next Regular';
    letter-spacing: 0.315324px;
}

.createAccount form .inputCheck label a {
    color: #ffffff;
    text-decoration: underline;
}

.createAccount form .btn {
    margin: 20px 0 0;
}

.yellowBtn {
    /* width: 180px; */
    height: 59px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 17px 36px 16px;
    background: rgba(231, 158, 31, 0.8);
    border-radius: 10px;
    font-size: 18px;
    line-height: 25px;
    color: #FFFFFF;
    outline: none;
    border: 0;
    box-shadow: none;
    text-transform: uppercase;
}

.yellowBtn:hover {
    background: rgba(62, 62, 62, 0.9);
    color: #E79E1F;
}

/* .yellowBtn:focus {
    background: rgba(62, 62, 62, 0.9);
    box-shadow: none;
    color: #E79E1F;
} */

.yellowBtn:active:focus {
    background: rgba(62, 62, 62, 0.9);
    box-shadow: none;
    color: #E79E1F;
}

.darkBtn {
    margin: 56px auto 0;
    /* width: 180px; */
    height: 59px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 17px 36px 16px;
    background: rgba(62, 62, 62, 0.9);
    border-radius: 10px;
    font-size: 18px;
    line-height: 25px;
    color: #E79E1F;
    outline: none;
    border: 0;
    box-shadow: none;
    text-transform: uppercase;
}

.darkBtn:hover {
    background: rgba(231, 158, 31, 0.8);
    color: #FFFFFF;
}

.darkBtn:focus {
    background: rgba(231, 158, 31, 0.8);
    box-shadow: none;
    color: #FFFFFF;
}

.darkBtn:active:focus {
    background: rgba(231, 158, 31, 0.8);
    box-shadow: none;
    color: #FFFFFF;
}

header {
    position: relative;
    /* top: 0; */
    /* left: 0; */
    width: 100%;
    padding: 0 32px;
    z-index: 999;
    background-color: #020829;
}

.landingPage .intro {
    width: 100%;
    min-height: calc(100vh - 72px);
    /* padding: 376px 0 322px; */
    display: flex;
    justify-content: space-around;
    /* align-items: center; */
    background: url('../images/homeBg1.jpg') center;
    background-repeat: no-repeat;
    background-size: cover;
}
.background-img {
    width: 100%;
    min-height: calc(100vh - 72px);
    /* padding: 376px 0 322px; */
    display: flex;
    justify-content: space-around;
    /* align-items: center; */
    background: url('../images/homeBg1.jpg') center;
    background-repeat: no-repeat;
    background-size: cover;
}
.bg-img {
    background: url('../images/homeBg1.jpg') center;
    background-repeat: no-repeat;
    background-size: cover;
}

.landingPage .intro .content {
    width: 760px;
    padding: 50px 45px 45px;
    position: relative;
    z-index: 2;
}
.landingPage .intro .form {
    width: 760px;
    padding: 50px 45px 45px;
    position: relative;
    z-index: 2;
}


.landingPage .intro .content:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #272D35;
    opacity: 0.7;
    z-index: -1;
}
.landingPage .intro .form:before {
    content: "";
    position: absolute;
    top: 0;
    left: 220;
    width: 80%;
    height: 100%;
    background: #272D35;
    opacity: 0.7;
    z-index: -1;
}

.landingPage .intro .content p {
    font-family: 'Avenir Next Medium';
    font-style: normal;
    font-weight: 500;
    font-size: 28px;
    line-height: 38px;
    text-align: center;
    color: #FFFFFF;
    margin-bottom: 25px;
}

.landingPage .intro .content a {
    font-family: 'Avenir Next Regular';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    text-decoration-line: none;
    color: #FFFFFF;
}

.navbar {
    padding: 0;
}

.navbar-toggler {
    /* box-shadow: rgba(255, 255, 255, 0.55) 0px 0px 0px 2px; */
}

.navbar-brand {
    margin-right: 56px;
    padding: 0;
}

.nav-link {
    font-family: 'Avenir Next Regular';
    font-weight: 400;
    font-size: 22px;
    line-height: 25px;
    color: #FFFFFF;
    padding-left: 0;
    padding-right: 0;
    margin-left: 34px;
}

.nav-link:focus,
.nav-link:hover,
.nav-link.active {
    color: rgba(249, 166, 24, 1) !important;
}

header .navLeft {
    display: flex;
    flex: 50%;
    justify-content: flex-end;
}

header .searchBlock {
    /* width: 679px; */
    width: 63%;
    height: 47px;
    position: relative;
}

header .searchBlock .dropdown .dropdown-toggle {
    width: 100%;
    background: transparent;
    border-radius: 0;
    padding: 0;
}

header .searchBlock .dropdown .dropdown-toggle::after {
    display: none;
}

header .searchBlock .dropdown .dropdown-toggle form {
    width: 100%;
}

header .searchBlock .dropdown .dropdown-menu {
    width: 100%;
    background: #2E3C49;
    border-radius: 0px 0px 50px 50px;
    margin-top: 0;
    padding: 50px 32px 50px;
}

header .searchBlock .dropdown .dropdown-menu h4 {
    font-family: 'Avenir Next Medium';
    font-weight: 500;
    font-size: 18px;
    line-height: 25px;
    letter-spacing: 0.315324px;
    color: #FFFFFF;
    margin-bottom: 20px;
}

header .searchBlock .dropdown .dropdown-menu .recentBox {
    display: grid;
    grid-template-columns: auto auto auto;
    grid-gap: 0 45px;
    margin-bottom: 40px;
}

header .searchBlock .dropdown .dropdown-menu .recentBox .image img {
    width: 100%;
    margin-bottom: 12px;
}

header .searchBlock .dropdown .dropdown-menu .recentBox h5 {
    font-family: 'Avenir Next Demi';
    font-weight: 600;
    font-size: 15px;
    line-height: 25px;
    text-align: center;
    letter-spacing: 0.315324px;
    color: #FFFFFF;
}

header .searchBlock .dropdown .dropdown-menu .recomendations .recList {
    background: #374858;
    border-radius: 15px;
    padding: 8px 12px;
    display: flex;
    align-items: center;
    margin-bottom: 16px;
}

header .searchBlock .dropdown .dropdown-menu .recomendations .image {
    width: 68.89px;
    margin-right: 20px;
    border-radius: 10px;
    overflow: hidden;
}

header .searchBlock .dropdown .dropdown-menu .recomendations .image img {
    width: 100%;
}

header .searchBlock .dropdown .dropdown-menu .recomendations .text {
    font-family: 'Avenir Next Medium';
    font-weight: 500;
    font-size: 18px;
    line-height: 25px;
    letter-spacing: 0.315324px;
    color: #E79E1F;
}

header .searchBlock .searchInput {
    width: 100%;
    height: 47px;
    background: #272B34;
    border-radius: 10px;
    border: 0;
    box-shadow: none !important;
    color: #ffffff;
    font-family: 'Avenir Next Demi';
    font-weight: 600;
    font-size: 18px;
    line-height: 25px;
    padding: 10px 38px 10px 15px;
}

header .searchBlock .searchInput::placeholder {
    color: #ffffff;
    opacity: 1;
}

header .searchBlock .dropdown.show .searchInput {
    border-radius: 10px 10px 0px 0px;
}

header .searchBlock .searchIcon {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 15px;
    background: url('../images/search.png') no-repeat;
    background-size: 22px 23px;
    background-position: center;
    border: 0;
    box-shadow: none !important;
}

footer {
    /* background: #17191C; */
    background: #020829;
    padding: 53px 0;
}

footer .footerlogo {
    margin-bottom: 12px;
}

footer .footerContact {
    max-width: 305px;
}

footer .footerContact p,
footer .footerContact p a {
    margin-bottom: 25px;
    font-family: 'Avenir Next Regular';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 25px;
/* color: #93928D; */
color: white;
}

footer .footerContact p:last-child {
    margin: 0;
}

footer h4 {
    font-family: 'Avenir Next Demi';
    font-weight: 600;
    font-size: 20px;
    line-height: 27px;
    /* color: #F9A618; */
    color: #f7a817;
    margin-bottom: 30px;
}

footer ul {
    list-style: none;
    padding: 0;
}

footer li {
    margin-bottom: 12px;
}

footer li a {
    font-family: 'Avenir Next Demi';
    font-weight: 600;
    font-size: 17px;
    line-height: 23px;
    /* color: #C1C0C0; */
    color: white;
    text-decoration: none;
}
footer li a:hover{
    color: #F9A618;
}
.subscribe {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 45px;
}

.subscribe p {
    font-family: 'Avenir Next Regular';
    font-weight: 400;
    font-size: 18px;
    line-height: 25px;
    color: #6D6D6D;
    margin: 0 10px 0 0;
}

.subsForm {
    max-width: 575px;
    position: relative;
}

.subsForm .form-control {
    width: 527px;
    height: 53px;
    background: rgba(31, 39, 50, 0.9);
    border-radius: 40px;
    border: 0;
    box-shadow: none !important;
    font-family: 'Avenir Next Demi';
    font-weight: 600;
    font-size: 18px;
    line-height: 25px;
    color: #808080;
    padding: 12px 176px 12px 24px;
}

.subsForm .subsBtn {
    position: absolute;
    top: 0;
    right: 0;
    margin: 0;
    height: 53px;
    background: rgba(249, 166, 24, 0.72);
    border: 1px solid #000000;
    border-radius: 30px;
}

.subsForm .subsBtn:hover {
    background: rgba(62, 62, 62, 0.9);
    color: #ffffff;
}

.regBtn {
    font-family: 'Avenir Next Demi';
    font-weight: 600;
    font-size: 18px;
    line-height: 25px;
    color: #FFFFFF;
    background: rgba(249, 166, 24, 0.67);
    padding: 11px 17px 12px;
    white-space: nowrap;
    margin-left: 28px;
    text-decoration: none;
    height: 47px;
    display: inline-block;
    border-radius: 10px 10px;
}

.regBtn:hover {
    color: #ffffff;
}

.gift {
    padding: 100px 0 100px;
    background: url('../images/homeBg1.jpg') repeat;
    background-size: contain;
}

.gift h2 {
    margin-bottom: 25px;
}

.gift p {
    font-family: 'Avenir Next Demi';
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
    color: #DBDBDB;
}

.planBtn {
    margin-top: 40px;
    display: inline-block;
    position: relative;
    text-decoration: none;
}

.planBtn span {
    position: relative;
    z-index: 1;
    width: 100%;
    padding: 17px 50px;
    font-family: 'Avenir Next Demi';
    font-weight: 600;
    font-size: 18px;
    line-height: 25px;
    color: #FFFFFF;
    border: 1px solid #000000;
    display: block;
    background: rgba(31, 39, 50, 1);
}

.planBtn:before {
    content: "";
    height: 20px;
    width: 20px;
    position: absolute;
    top: -2px;
    right: -2px;
    background: #F9A618;
    z-index: 0;
}

.planBtn:after {
    content: "";
    height: 20px;
    width: 20px;
    position: absolute;
    bottom: -2px;
    left: -2px;
    background: #F9A618;
    z-index: 0;
}

.planBtn:hover span {
    background: #F9A618;
    color: #FFFFFF;
}

.howItWorks {
    padding: 16px 0 100px;
    background: url('../images/homeBg1.jpg') repeat;
    background-size: contain;
}

.howItWorks h2 {
    margin-bottom: 100px;
    margin-top: 50px;
}

.howItWorks .videoContainer {
    max-width: 1136px;
    height: 474px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    z-index: 1;
}

.howItWorks .videoContainer:before {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    max-width: 70%;
    height: 474px;
    margin: 0 auto;
    border: 3px solid rgba(249, 166, 24, 0.3);
    z-index: -1;
}

.howItWorks .videoBlock {
    margin: 0 20px;
    max-width: 508px;
}

.howItWorks .videoBlock img {
    max-width: 100%;
}

.howItWorks .videoContainer  iframe{
    min-width: 900px;
}

.download {
    background: url('../images/homeBg1.jpg') repeat;
    background-size: contain;
    padding-bottom: 130px;
}

.download .image {
    margin-top: -290px;
    margin-bottom: -320px;
}

.download .btnBlock {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

.download .googleplay {
    margin-left: 100px;
}

.innerpage {
    background-image: url('../images/homeBg1.jpg'), url('../images/gradient.png');
    background-repeat: no-repeat, repeat;
    min-height: 57vh;
    background-size: cover, auto auto;
}

.innerpage header {
    position: static;
    padding: 68px 32px;
    padding-left: 0;
}

.nav-link {
    margin-left: 0;
    margin-right: 34px
}

.welcome {
    margin-bottom: 50px;
}

.welcome h4 {
    font-family: 'Avenir Next Demi';
    font-weight: 600;
    font-size: 25px;
    line-height: 25px;
    text-align: center;
    letter-spacing: 0.315324px;
    color: #FFFFFF;
}

.welcome p {
    font-family: 'Avenir Next Regular';
    font-weight: 400;
    font-size: 20px;
    line-height: 40px;
    letter-spacing: 0.315324px;
    color: #FFFFFF;
    max-width: 550px;
}

.welcome p span {
    color: #E79E1F;
}

.boxes .boxListFolder {
    background: linear-gradient(268.67deg, rgba(26, 32, 46, 0.7) 1.01%, rgba(26, 32, 46, 0.679) 78.02%, rgba(26, 32, 46, 0.455) 98.78%);
    border-radius: 200px 200px 0px 0px;
    margin-top: 117px;
    padding: 0 7% 65px;
    position: relative;
}

.boxes .boxListFolder .userDetails {
    width: 100%;
    height: 158px;
    margin: 0 auto 20px;
    position: relative;
    top: -70px;
}

.boxes .boxListFolder .userDetails .img {
    width: 155px;
    height: 158px;
    border: 2px solid rgba(175, 175, 175, 0.9);
    border-radius: 20px;
    margin: 0 auto 22px;
    overflow: hidden;
}

.boxes .boxListFolder .userDetails .img img {
    width: 100%;
    height: 100%;
}

.boxes .boxListFolder .userDetails .userName {
    font-family: 'Avenir Next Demi';
    font-weight: 600;
    font-size: 15px;
    line-height: 25px;
    text-align: center;
    letter-spacing: 0.315324px;
    color: #FFFFFF;
}

.boxes .boxListFolder ul {
    padding: 0 25px;
    list-style: none;
    /* display: flex; */
    flex-wrap: wrap;
    justify-content: space-between;
    max-width: 1150px;
    margin: 0 auto 37px;
    display: grid;
    grid-template-columns: auto auto auto auto;
    grid-gap: 0 20px;
}

.boxes .boxListFolder ul li {
    width: 170px;
    margin-bottom: 75px;
}

.boxes .boxListFolder ul li .folderView {
    width: 170px;
    height: 179px;
    background: rgba(158, 171, 184, 0.5);
    border-width: 3px 0px 0px 3px;
    border-style: solid;
    border-color: rgba(175, 175, 175, 0.9);
    border-radius: 30px;
}

.boxes .boxListFolder ul li .folderView .folders {
    padding: 16px 20px 16px 27px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.boxes .boxListFolder ul li .folderView .msgBox,
.boxes .boxListFolder ul li .folderView .imgBox {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.boxes .boxListFolder ul li .folderView img {
    width: 85%;
}

.boxes .boxListFolder ul li .folderView .folders span {
    background: #E79E1F;
    border-radius: 10px;
    width: 49px;
    height: 48px;
    margin-bottom: 19px;
    display: block;
}

.boxes .boxListFolder ul li .folderTitle {
    font-family: 'Avenir Next Demi';
    font-weight: 600;
    font-size: 15px;
    line-height: 25px;
    text-align: center;
    letter-spacing: 0.315324px;
    color: #FFFFFF;
    padding-top: 12px;
}

.boxes .sort select {
    width: 150px;
    height: 45px;
    background: url("../images/sortButton.png") 94% center rgba(62, 62, 62, 0.9);
    background-size: auto;
    background-repeat: no-repeat;
    border-radius: 10px;
    font-family: 'Avenir Next Medium';
    font-weight: 500;
    font-size: 18px;
    line-height: 25px;
    letter-spacing: 0.315324px;
    color: #E79E1F;
    outline: none !important;
    box-shadow: none !important;
    border: 0 !important;
}

.sort .dropdown .dropdown-toggle::after {
    border: 0;
    background: url('../images/sortButton.png') no-repeat center;
    width: 22px;
    height: 27px;
}

.boxes h5 {
    font-family: 'Avenir Next Demi';
    font-weight: 600;
    font-size: 25px;
    line-height: 25px;
    letter-spacing: 0.315324px;
    color: #FFFFFF;
}

.boxes .boxList ul {
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    /* justify-content: space-between; */
    list-style: none;
    column-gap: 30px;
    /* display: grid;
    justify-content: space-between;
    grid-template-columns: auto auto auto auto;
    row-gap: 60px; */
}

.boxes .boxList li {
    background: linear-gradient(180deg, rgba(24, 43, 64, 0.56) 0%, rgba(38, 63, 90) 100%);
    box-shadow: -1px 1px 3px 0px #afafaf;
    border-radius: 40px;
    width: 247px;
    height: 270px;
    padding: 0px 0px 0px 15px;
    margin-bottom: 20px;
    position: relative;
}

.boxes .boxList li .count {
    width: 60px;
    height: 60px;
    left: -22px;
    top: -19px;
    border-radius: 50%;
    background: #E79E1F;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Avenir Next Bold';
    font-size: 23px;
    line-height: 25px;
    text-align: center;
    color: #FFFFFF;
}

.menuIcon {
    position: absolute;
    top: 28px;
    right: 25px;
}

.boxes .boxList .image {
    width: 190px;
    height: 190.31px;
    border-radius: 30px;
    overflow: hidden;
    margin-bottom: 0px;
}

.boxes .boxList .image img {
    max-width: 100%;
}

.boxes .boxList h5 {
    font-family: 'Avenir Next Demi';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 1.3;
    letter-spacing: 0.315324px;
    color: #E79E1F;
    margin-bottom: 0;
}

.boxes .boxList .spaceAcquired {
    margin-bottom: 7px;
}

.boxes .boxList .spaceAcquired .progress {
    height: 5px;
    border-radius: 0;
}

.boxes .boxList .spaceAcquired .progress .progress-bar {
    background: rgb(82, 96, 140);
    background: linear-gradient(90deg, rgba(82, 96, 140, 0.8687850140056023) 72%, rgba(100, 105, 231, 0.5578606442577031) 89%);
}

.boxes .boxList p {
    font-family: 'Avenir Next Regular';
    font-weight: 400;
    font-size: 15px;
    line-height: 25px;
    color: #F1F1F1;
}
.publicBoxList{
    border: 1px solid grey;
    border-radius: 30px;
    padding: 20px 0px 20px 60px;
}
.publicBoxListHeading{
    text-align: center;
    padding-bottom: 60px;
}
.publicBoxListBoxName{
    display: block;
    width: 100px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.loadMoreBtn {
    background: rgba(62, 62, 62, 0.9);
    border-radius: 10px;
    font-family: 'Avenir Next Medium';
    font-weight: 500;
    font-size: 18px;
    line-height: 25px;
    letter-spacing: 0.315324px;
    color: #E79E1F;
    padding: 9px 20px 10px;
    display: inline-block;
    text-decoration: none;
    border: 0 !important;
    box-shadow: none !important;
}

.loadMoreBtn:hover,
.loadMoreBtn:focus {
    color: #ffffff;
    background: #E79E1F;
}

.boxes.createBox {
    margin-bottom: 0;
}

.plans {
    margin-bottom: 150px;
}

.plans .planList {
    display: flex;
    /* grid-template-columns: auto auto auto; */
    grid-gap: 0 20px;
    /* padding-right: 20px; */
}

.plans .planList .listItem {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    flex-flow: column;
}

.plans .planList .listItem:after {
    content: "";
    position: absolute;
    top: 23%;
    width: 100%;
    height: 68%;
    border-right: 1px solid #FFFFFF;
    margin-left: 5px;
    display: none;
}

.plans .planList .listItem .chooseBtn {
    /* background: rgba(62, 62, 62, 0.9); */
    border-radius: 22px;
    font-family: 'Avenir Next Demi';
    font-weight: 800;
    font-size: 22px;
    line-height: 25px;
    /* color: #E79E1F; */
    color: #fff;
    text-decoration: none;
    padding: 12px 46px;
    display: inline-block;
    margin-top: 10px;
    background-color: #0e0a6d;
    border: none;
}

.plans .planList .listHead {
    background-color: #031030;
    /* background: rgba(239, 161, 26, 0.13); */
    height: 200px;
    padding: 26px 20px 23px;
    margin-bottom: 25px;
    /* position: relative;
    z-index: 1; */
}

/* .plans .planList .listHead:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(180deg, rgba(85, 89, 103, .56), rgba(188, 192, 211, .5264));
    opacity: .3;
    z-index: -1;
} */

.plans .planList .listHead h3 {
    font-family: 'Avenir Next Medium';
    font-weight: 900;
    font-size: 35px;
    line-height: 25px;
    letter-spacing: 0.315324px;
    margin-bottom: 15px;
}

.plans .planList .listHead p {
    font-family: 'Avenir Next Regular';
    font-weight: 400;
    font-size: 18px;
    line-height: 25px;
    letter-spacing: 0.315324px;
    color: #ffffff;
}

.plans .planList .rate {
    padding-top: 60px;
    font-family: 'Avenir Next Regular';
    font-weight: 800;
    font-size: 25px;
    line-height: 25px;
    letter-spacing: 0.315324px;
    color: #f7a817;
    text-align: right;
}

.plans .planList .rate span {
    font-family: 'Avenir Next Regular';
    font-weight: 400;
    font-size: 18px;
    line-height: 25px;
    letter-spacing: 0.315324px;
    color: #ffffff;
    display: block;
}

.plans .planList ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.plans .planList ul:nth-of-type(1) {
    margin: 0 0 100px;
}

.plans .planList ul li {
    font-family: 'Avenir Next Regular';
    font-weight: 400;
    font-size: 18px;
    line-height: 25px;
    letter-spacing: 0.315324px;
    color: #FFFFFF;
    margin-bottom: 16px;
    position: relative;
    padding-left: 30px;
    padding-right: 30px;
}

.plans .planList ul li::before {
    position: absolute;
    left: 7px;
    content: "\2022";
    color: #E79E1F;
    font-weight: bold;
    display: inline-block;
    width: 24px;
}

.menuIconfliterBtn {
    padding-right: 23px;
    position: absolute;
    right: 110px;
    top: 50px;
}

.addToBox {
    margin-right: 23px;
    padding: 10px 20px;
    height: auto;
}
.floatingBtnBlock{
    position: absolute;
    right: 110px;
    top: 50px;
}
.dropdown .dropdown-toggle {
    width: 150px;
    background: rgba(62, 62, 62, 0.9);
    border-radius: 10px;
    font-family: 'Avenir Next Medium';
    font-weight: 500;
    font-size: 18px;
    line-height: 25px;
    letter-spacing: 0.315324px;
    color: #E79E1F;
    outline: none;
    box-shadow: none !important;
    border: 0 !important;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.menuIconfliterBtn .dropdown .dropdown-toggle::after {
    border: 0;
    background: url('../images/filter-icon.png') no-repeat center;
    width: 23px;
    height: 23px;
}
.menuIcon .dropdown .dropdown-toggle::after {
    border: 0;
    background: url('../images/dot.png') no-repeat center;
    width: 30px;
    height: 40px;
}

.dropdown .dropdown-menu {
    /* width: 280px; */
    width: 220px;
    /* margin-left: -130px; */
    /* background: rgba(46, 60, 73, 0.96); */
    background: linear-gradient(268.67deg,
                #1A202E 1.01%, 
                rgba(26, 32, 46, 0.97) 78.02%, 
                rgba(26, 32, 46, 0.65) 98.78%);
    border-radius: 20px 20px 20px 20px;
    padding: 20px 12px 30px;
}

.dropdown .dropdown-menu .dropdown-item {
    font-family: 'Avenir Next Demi';
    font-weight: 600;
    font-size: 17px;
    line-height: 25px;
    letter-spacing: 0.315324px;
    color: #FFFFFF;
    padding: 0;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
}
.dropdown .dropdown-menu .dropdown-item span{
    margin-left: auto;
}
.dropdown .dropdown-menu .dropdown-item:hover {
    background: transparent;
    text-decoration: underline;
}

.dropdown .dropdown-menu .dropdown-item img {
    vertical-align: middle;
    margin-right: 16px;
}

.dropdown .dropdown-menu .dropdown-item:last-child {
    margin: 0;
}
.aboutUsMainContent{
    /* background: linear-gradient(268.67deg, rgba(26, 32, 46, 0.7) 1.01%, rgba(26, 32, 46, 0.679) 78.02%, rgba(26, 32, 46, 0.455) 98.78%); */
    background: #031030;
    padding: 30px 30px 30px 30px;
    border-radius: 20px;
}
.aboutUsMainContent p{
    margin-bottom: 40px;
    text-align: left;
}
.aboutUsMainContent .heading{
    text-align: center;
}
.charity_publicBox {
    background: url('../images/homeBg1.jpg') center;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 160px;
    margin-bottom: 0px !important;
}

.charity {
    margin-bottom: 33px;
}

/* .charity h2 {
    font-family: 'Avenir Next Bold';
    font-weight: 700;
    font-size: 25px;
    line-height: 25px;
    letter-spacing: 0.315324px;
    color: #FFFFFF;
    margin-bottom: 15px;
} */

.charity p.subText {
    font-family: 'Avenir Next Medium';
    font-weight: 500;
    font-size: 18px;
    line-height: 25px;
    letter-spacing: 0.315324px;
    color: #FFFFFF;
    margin-bottom: 50px;
}

.charity .charityList {
    display: flex;
    background: linear-gradient(268.67deg, rgba(26, 32, 46, 0.7) 1.01%, rgba(26, 32, 46, 0.679) 78.02%, rgba(26, 32, 46, 0.455) 98.78%);
    border-radius: 80px;
    padding: 43px 43px 37px;
    margin-bottom: 20px;
}

.charity .charityList .contentImg {
    width: 220px;
    height: 220px;
    border-radius: 50px;
    overflow: hidden;
}

.charity .charityList .contentDesc {
    width: calc(100% - 220px - 24px);
}

.charity .charityList .contentImg {
    margin-right: 24px;
}

.charity .charityList h4 {
    font-family: 'Avenir Next Demi';
    font-weight: 600;
    font-size: 20px;
    line-height: 25px;
    letter-spacing: 0.315324px;
    color: #E79E1F;
    margin: 10px 0 0;
}

.charity .charityList p {
    font-family: 'Avenir Next Medium';
    font-weight: 500;
    font-size: 18px;
    line-height: 25px;
    letter-spacing: 0.315324px;
    color: #FFFFFF;
}

.charity .charityList .callus img {
    margin-right: 7px;
}

.charity .charityList .callus a,
.charity .charityList .callus span {
    font-family: 'Avenir Next Regular';
    font-weight: 400;
    font-size: 15px;
    line-height: 25px;
    letter-spacing: 0.315324px;
    color: #FFFFFF;
    text-decoration: none;
}

.charity .charityList h4 a {
    color: #E79E1F;
}

.discoverBoxes {
    margin-bottom: 55px;
}

.discoverBoxes h2 {
    font-family: 'Avenir Next Demi';
    font-weight: 600;
    font-size: 25px;
    line-height: 25px;
    letter-spacing: 0.315324px;
    color: #FFFFFF;
    margin-bottom: 110px;
}

.discoverBoxes .discoverBoxesList {
    display: grid;
    grid-template-columns: auto auto auto auto;
    grid-gap: 120px 25px;
    margin-bottom: 100px;
}

.discoverBoxes .discoverBoxesList .discoverBoxesItem {
    /* width: 292px; */
    max-width: 100%;
    /* height: 336px; */
    border-radius: 40px;
    position: relative;
    z-index: 1;
    padding: 0 26px 45px;
}

.discoverBoxes .discoverBoxesList .discoverBoxesItem:before {
    content: "";
    width: 100%;
    height: 100%;
    background: linear-gradient(180deg, rgba(85, 89, 103, 0.56) 0%, rgba(188, 192, 211, 0.5264) 100%);
    border-radius: 40px;
    border-width: 0px 0px 2px 2px;
    border-style: solid;
    border-color: #AFAFAF;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0.3;
    z-index: -1;
}

.discoverBoxes .discoverBoxesList .image {
    margin-top: -51px;
}

.discoverBoxes .discoverBoxesList .image .userImg {
    width: 111px;
    height: 111px;
    border-radius: 50%;
    overflow: hidden;
    margin: 0 auto;
    margin-bottom: 13px;
}

.discoverBoxes .discoverBoxesList .image h5 {
    font-family: 'Avenir Next Demi';
    font-weight: 600;
    font-size: 20px;
    line-height: 25px;
    text-align: center;
    letter-spacing: 0.315324px;
    color: #E79E1F;
    margin-bottom: 30px;
}

.discoverBoxes .discoverBoxesList p {
    font-family: 'Avenir Next Regular';
    font-weight: 400;
    font-size: 15px;
    line-height: 25px;
    letter-spacing: 0.315324px;
    color: #F1F1F1;
}

.discoverBoxes .discoverBoxesList p .quotes {
    margin-right: 3px;
}

.discoverBoxes .discoverBoxesList p .quotes img {
    vertical-align: top;
}

.openDiscoverBox_publicPage {
    padding-top: 25px !important;
    padding-bottom: 50px !important;
    background: url('../images/homeBg1.jpg') center;
    background-repeat: no-repeat;
    background-size: cover;
}

.openDiscoverBox {
    padding: 120px 0 0;
}

.openDiscoverBox .contentWrap {
    background: linear-gradient(268.67deg, rgba(26, 32, 46, 0.7) 1.01%, rgba(26, 32, 46, 0.679) 78.02%, rgba(26, 32, 46, 0.455) 98.78%);
    border-radius: 200px 200px 0px 0px;
    padding: 0 75px 40px;
    min-height: 850px;
}

.openDiscoverBox .image {
    top: -100px;
    position: relative;
}

.openDiscoverBox .image .userImg {
    width: 200px;
    height: 200px;
    border-radius: 50%;
    margin: 0 auto 20px;
}

.openDiscoverBox .image h5 {
    font-family: 'Avenir Next Demi';
    font-weight: 600;
    font-size: 26px;
    line-height: 25px;
    text-align: center;
    letter-spacing: 0.315324px;
    color: #E79E1F;
}

.openDiscoverBox .location {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 50px;
}

.openDiscoverBox .location .text {
    max-width: 314px;
    font-family: 'Avenir Next Regular';
    font-weight: 400;
    font-size: 15px;
    line-height: 25px;
    letter-spacing: 0.315324px;
    color: #FFFFFF;
}

.openDiscoverBox .location a {
    font-family: 'Avenir Next Regular';
    font-weight: 400;
    font-size: 15px;
    line-height: 25px;
    letter-spacing: 0.315324px;
    color: #FFFFFF;
    text-decoration: none;
}

.openDiscoverBox .location a img {
    margin-right: 7px;
}

.openDiscoverBox .textContent p {
    font-family: 'Avenir Next Regular';
    font-weight: 400;
    font-size: 20px;
    line-height: 25px;
    letter-spacing: 0.315324px;
    color: #F1F1F1;
}

.openDiscoverBox .textContent p .quotes {
    margin-right: 5px;
}

.openDiscoverBox .textContent p .quotes img {
    vertical-align: top;
}

.modal-content {
    /* background: #2E3C49; */
    background: rgba(11,19,32,0.9);
    border-radius: 20px;
    cursor: pointer;
}

.buyModal h4 {
    font-family: 'Avenir Next Demi';
    font-weight: 600;
    font-size: 30px;
    line-height: 25px;
    letter-spacing: 0.315324px;
    color: #E79E1F;
    margin-bottom: 20px;
}

.buyModal p {
    font-family: 'Avenir Next Medium';
    font-weight: 500;
    font-size: 20px;
    line-height: 27px;
    color: #FFFFFF;
    margin-bottom: 30px;
}

.btn-close {
    background: url('../images/Close.png') no-repeat;
    opacity: 1;
    position: relative;
    top: 30px;
    right: 20px;
    z-index: 99;
}

.modal-body {
    padding: 0 32px 34px;
}

.uploadModal h4 {
    font-family: 'Avenir Next Demi';
    font-weight: 600;
    font-size: 20px;
    line-height: 25px;
    letter-spacing: 0.315324px;
    color: #FFFFFF;
    margin-bottom: 25px;
}

.uploadModal .uploadItems {
    display: grid;
    grid-template-columns: auto auto auto;
    grid-gap: 28px 104px;
    justify-content: space-between;
}
.uploadModal .uploadItems .upload-item-list {
    height: 100px;
    position: relative;
    text-align: center;
}

.uploadModal .uploadItems .img {
    width: 60px;
    height: 60px;
    background: #E79E1F;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto 4px;
}

.uploadModal .uploadItems h5 {
    font-family: 'Avenir Next Medium';
    font-weight: 500;
    font-size: 18px;
    line-height: 25px;
    text-align: center;
    letter-spacing: 0.315324px;
    color: rgba(216, 227, 227, 0.82);
}
.uploadModal .uploadItems .upload-item-list .icon-lbl {
    /* position: absolute;
    bottom: -10px;
    left: 0;
    right: 0; */
    text-align: center; 
}
.ScheduleModal h4 {
    font-family: 'Avenir Next Demi';
    font-weight: 600;
    font-size: 20px;
    line-height: 25px;
    letter-spacing: 0.315324px;
    color: #FFFFFF;
    margin-bottom: 30px;
}

.ScheduleModal .selection {
    display: flex;
    justify-content: space-between;
}

.ScheduleModal .customSelect {
    width: 99px;
}

.ScheduleModal .customSelect .wrap {
    position: relative;
}

.ScheduleModal .customSelect .arrow {
    width: 21px;
    height: 16px;
    position: absolute;
    top: 0;
    right: 8px;
    bottom: 0;
    margin: auto;
    background: url('../images/down-arrow.png') no-repeat center;
}

.ScheduleModal label {
    font-family: 'Avenir Next Regular';
    font-weight: 400;
    font-size: 15px;
    line-height: 25px;
    letter-spacing: 0.315324px;
    color: #FFFFFF;
}

.ScheduleModal select {
    background: #FFFFFF;
    border: 2px solid #E79E1F !important;
    border-radius: 0px 0px 10px 10px;
    font-family: 'Avenir Next Regular';
    font-weight: 400;
    font-size: 15px;
    line-height: 25px;
    letter-spacing: 0.315324px;
    color: #000000;
    box-shadow: none !important;
}

.editProfileModal {
    padding-top: 70px;
}

.editProfileModal .modal-content {
    border-radius: 70px;
}

.editProfileModal .profileImg {
    position: relative;
    margin-top: -100px;
    margin-bottom: 40px;
}

.editProfileModal .profileImg .imgs {
    width: 140px;
    height: 140px;
    border-radius: 50%;
    overflow: hidden;
    margin: 0 auto 6px;
}

.editProfileModal .profileImg span {
    font-family: 'Avenir Next Regular';
    font-weight: 400;
    font-size: 15px;
    line-height: 25px;
    letter-spacing: 0.315324px;
    text-decoration-line: underline;
    color: #93B3E3;
    cursor: pointer;
}

.editProfileModal form .formRow {
    margin-bottom: 30px;
}

.editProfileModal form .form-label {
    color: #E79E1F;
    font-size: 18px;
    line-height: 25px;
    margin-bottom: 2px;
}

.editProfileModal form .form-control {
    font-family: 'Avenir Next Demi';
    font-size: 20px;
    line-height: 25px;
    color: #FFFFFF;
    background: transparent;
    border: 0;
    border-radius: 0;
    border-bottom: 3px solid #FFFFFF;
    outline: none;
    box-shadow: none;
    padding: 0 0 8px;
}

.editProfileModal .modal-body {
    padding: 0 90px 60px;
}

.pathmenu {
    position: fixed;
    top: 55%;
    right: 30px;
}

.pathmenu .menuIcon {
    width: 70px;
    height: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background: #E79E1F;
    transform: rotate(45deg);
}

.pathmenu .list {
    position: absolute;
}

.pathmenu .trash {
    left: -92px;
    top: -50px;
}

.pathmenu .edit {
    left: -168px;
    top: -18px;
}

.pathmenu .add {
    left: -185px;
    top: 57px;
}

/* .boxes.box-details .boxListFolder .userDetails .img{
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(158, 171, 184, 0.5);
    border: 1px solid #AFAFAF;
    box-shadow: 0px 4px 6px rgba(207, 207, 207, 0.25);
}
.boxes.box-details .boxListFolder .userDetails .img img{
    width: auto;
} */
.boxes.box-details .boxListFolder ul li {
    width: auto;
}

.boxes.box-details .boxListFolder ul li .folderView {
    width: 100%;
    height: auto;
    background: transparent;
    border: 0;
}

.boxes.box-details .messageBox .msgRow {
    border-bottom: 1px solid #ffffff;
    padding-bottom: 20px;
}

.boxes.box-details .messageBox .msgRow:last-child {
    border-bottom: 0;
}

.boxes.box-details .messageBox p {
    font-family: 'Avenir Next Medium';
    font-weight: 500;
    font-size: 18px;
    line-height: 25px;
    letter-spacing: 0.315324px;
    color: #FFFFFF;
}

.boxes.box-details .messageBox p:nth-last-of-type(1) {
    margin-bottom: 0;
}

.boxes.box-details .messageBox .folderTitle {
    font-size: 14px;
    font-family: 'Avenir Next Medium';
    font-weight: 500;
    color: #FFFFFF;
    text-align: right;
}

.boxes.box-details .imageBox {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    padding: 0 20px;
}

.boxes.box-details .imageBox .folderTitle {
    font-size: 14px;
    font-family: 'Avenir Next Medium';
    font-weight: 500;
    color: #FFFFFF;
    text-align: center;
}

.boxes.box-details .imageBox .imgBox {
    margin: 10px;
    width: 200px;
    text-align: center;
}

.boxes.box-details .docBox .rowBox {
    margin-bottom: 30px;
}

.boxes.box-details .docBox .rowBox .rowWrap {
    display: inline-block;
}

.boxes.box-details .docBox .date {
    font-size: 14px;
    font-family: 'Avenir Next Bold';
    font-weight: 700;
    color: #FFFFFF;
    text-align: right;
}

.boxes.box-details .docBox p {
    font-family: 'Avenir Next Medium';
    font-weight: 500;
    font-size: 18px;
    line-height: 25px;
    letter-spacing: 0.315324px;
    color: #FFFFFF;
}


.createBoxModal .editImg .imgs {
    width: 140px;
    height: 140px;
    background: linear-gradient(180deg, #FFFFFF 0%, rgba(231, 158, 31, 0.9) 100%);
    border: 1px solid #AFAFAF;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.createBoxModal ::placeholder {
    opacity: 1;
    color: #ffffff;
}

.createBoxModal .receipients {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}

.createBoxModal .receipients .addUserBtn {
    background: transparent;
    border: 0 !important;
    box-shadow: none !important;
}

.createBoxModal .receipients .wrap {
    display: flex;
    flex-wrap: wrap;
}

.createBoxModal .receipients .users {
    width: 60px;
    height: 60px;
    border-radius: 50%;
}

.createBoxModal .publicBox {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
}

.createBoxModal .publicBox span {
    font-family: 'Avenir Next Regular';
    font-weight: 400;
    font-size: 13px;
    line-height: 18px;
    color: #FFFFFF;
}

.switch {
    display: inline-block;
    height: 34px;
    position: relative;
    width: 60px;
}

.switch input {
    display: none;
}

.switch .slider {
    background-color: #ffffff;
    bottom: 0;
    cursor: pointer;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    transition: .4s;
}

.switch .slider:before {
    background-color: #2E3C49;
    bottom: 4px;
    content: "";
    height: 26px;
    left: 4px;
    position: absolute;
    transition: .4s;
    width: 26px;
}

.switch input:checked+.slider {
    background-color: #E79E1F;
}

.switch input:checked+.slider:before {
    transform: translateX(26px);
    background: #ffffff;
}

.switch .slider.round {
    border-radius: 34px;
}

.switch .slider.round:before {
    border-radius: 50%;
}

.myProfile {
    padding-bottom: 50px;
    background: rgba(97, 95, 95, 0.4);
    border-radius: 15px;
}

.myProfile label {
    font-family: 'Avenir Next Medium';
    font-weight: 500;
    font-size: 18px;
    line-height: 25px;
    letter-spacing: 0.315324px;
    color: #E79E1F;
    margin-bottom: 15px;
}

.myProfile .formRow {
    margin-bottom: 25px;
}

.imageInput {
    position: relative;
}

.imageInput span {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #000000;
}

.imageInput .image {
    height: 150px;
    width: 150px;
    border: 1px solid #E79E1F;
    background: url('../images/upload.png') no-repeat center #ffffff;
    background-size: 70px;
    cursor: pointer;
    border-radius: 10px;
}

.myProfile ::placeholder {
    opacity: 1;
    color: #000000;
}

.myProfile .form-control,
.myProfile .form-select {
    font-family: "Avenir Next Demi";
    font-size: 20px;
    line-height: 50px;
    color: #000000;
    height: 50px;
    margin-bottom: 7px;
    padding: 0 1rem;
}
.myProfile .form-text {
    font-family: "Avenir Next Demi";
    font-size: 20px;
    line-height: 50px;
    color: #f1eded;
    height: 50px;
    margin-bottom: 7px;
}


.myProfile .publicBoxText,
.myProfile p {
    font-family: "Avenir Next Demi";
    font-size: 20px;
    line-height: 32px;
    color: #ffffff;
}

.userProfilePic {
    color: #ffffff;
}

.userProfilePic p {
    font-size: 18px;
    text-align: center;
    margin-bottom: 0;
}

.userProfilePic .pic {
    width: 100%;
    height: 100px;
    border-radius: 10%;
    overflow: hidden;
    margin: 0 auto 7px;
}

.userProfilePic .pic img {
    width: 165px!important;
    height: 100px;
}

.createBoxImg {
    width: 240px;
    height: 240px;
}

p.stickyHeader {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
}

.active-plan {
    border: 2px solid #E79E1F;
    border-radius: 23px;
}

.uploadModalIcons {
    /* width: 85%; */
    height: 50px;
    max-width: max-content;
    width: auto;
}

.cursorStatus:hover {
    cursor: not-allowed;
}

.carousel-indicators {
    margin-bottom: -40px !important;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    margin: 0;
}

.uploadBtn,
.clearBtn {
    width: 65;
    height: 35px;
    justify-content: center;
    align-items: center;
    padding: 5px 15px 5px;
    background: rgba(231, 158, 31, 0.8);
    border-radius: 10px;
    line-height: 25px;
    color: #FFFFFF;
    outline: none;
    border: 0;
    box-shadow: none;
}

.uploadBtn:hover,
.clearBtn:hover {
    background: rgba(62, 62, 62, 0.9);
    color: #E79E1F;
}

.carousel-indicators .active {
    border-top: unset;
    border-bottom: unset;
    border: 1px solid transparent;
    border-left-width: 0;
    border-right-width: 0;
    border-radius: 150%;
    background-color: #ffffff;
    height: 20px;
    width: 20px
}

.carousel-indicators [data-bs-target] {
    width: 20px;
    height: 20px;
    cursor: pointer;
    border-top: 1px solid transparent;
    border-bottom: 1px solid transparent;
    border-radius: 150%; 
    margin-right: 5px;
    margin-left: 5px;
}

.file-upload-input {
    padding: 10px;
    margin-left: auto;
    width: 58%;
    margin-top: 20px;

}

.file-upload-button {
    padding: 11px;
    width: 58%;
    margin-left: auto;
    margin-top: 20px;
}

.file-upload-button .uploadBtn {
    width: 100px
}
.file-upload-button .uploadDcBtn{
    background: rgba(62, 62, 62, 0.9);
    color: #E79E1F;
    width: fit-content;
}

.fileInput {
    width: 110px
}

.file-upload-message {
    text-align: center;
    /* margin-top: 20px; */
    width: 450px;
}

.message-text {
    height: 80px;
    max-width: 100%;
}

.tnc{
    background: linear-gradient(268.67deg, rgba(26, 32, 46, 0.7) 1.01%, rgba(26, 32, 46, 0.679) 78.02%, rgba(26, 32, 46, 0.455) 98.78%);
   padding: 30px 30px 30px 30px;
   border-radius: 20px;
}

.tnc .header{
    text-align: center;
    font-weight: bolder;
    
    margin-bottom: 20px;
}
.tnc .list li  {
    margin-bottom: 30px;
}

.tnc .list li span {
    margin-left: 40px;
}


.divSize {
    border-radius: 50%!important;
    width: 30%;
    padding: 18px 7vmin 27px 17px;
}

.backBtn {
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 18px 13px 15px 15px;
    background: rgba(62, 62, 62, 0.9);
    border-radius: 10px;
    font-size: 18px;
    line-height: 25px;
    color: #E79E1F;
    outline: none;
    border: 0;
    box-shadow: none;
    text-transform: uppercase;
}

.backBtn:hover{
    color: #FFFFFF;
    background: rgba(231, 158, 31, 0.8);
}
.cover-box-photo-display{
    height: 120px;
    width: 120px;
}
.activeDeactive{
    border: 1px solid grey;
    border-radius: 15px;
    /* padding: 5px; */
    margin-left: -10px;
    width: 80%;
    height: 50px;
}
.admin-user-profileImage{
    height: 220px;
    width: 220px;
}

.admin-user-profile  form .form-text {
    font-family: 'Avenir Next Demi';
    font-size: 20px;
    line-height: 25px;
    color: #FFFFFF;
    background: transparent;
    border: 0;
    border-radius: 0;
    border-bottom: 3px solid #FFFFFF;
    outline: none;
    box-shadow: none;
    padding: 0 0 8px;
}
.paymentDetailData{
    text-align: center;
}
.paymentDetailTable{
    border-radius: 30px;
    padding: 10px;
    margin-top: 50px;
}
.usersManageFolders .img{
    width: 40px;
}
.adminFolderManageModal{
    width: 60px;
}
.adminLoader{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.blink {
    animation: blinker 1s linear infinite;
    font-family: sans-serif;
}
@keyframes blinker {
    50% {
        opacity: 0;
    }
} 


.btn.disabled, .btn:disabled, fieldset:disabled .btn {
    background-color: #747474;
}
.menuIcon .dropdown .dropdown-toggle {
    width: max-content;
    /* height: fit-content; */
    background: none;
   /*  border-radius: 10px; */
    
    outline: none;
    box-shadow: none !important;
    border: 0 !important;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.lds-dual-ring {
    display: inline-block;
    width: 80px;
    height: 80px;
    position: relative;
  }
  .lds-dual-ring:after {
    content: " ";
    display: block;
    width: 64px;
    height: 64px;
    margin: 65px auto;
    border-radius: 50%;
    border: 6px solid #fff;
    border-color: #ffe604 transparent #ffe604 transparent;
    animation: lds-dual-ring 1.2s linear infinite;
  }
  @keyframes lds-dual-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }


/*********************************************
    CSS Elements - RP
***************************/
.landing-page-container header .dotMenuBtn {
    display: none;
}
header .plan-dtl-row {
    position: absolute;
    right: 20px;
    top: 17px;
}
header .plan-dtl-row .plan-dtl-lbl {
    display: flex;
    justify-content: flex-end;
}

.boxes .box-list-row ul li {
    width: 245px;
    padding: 15px 15px;
}
.boxes .box-list-row ul li .menuIcon {
    top: 13px;
    right: 0px;
}
.boxes .box-list-row ul li .image img {
    height: 190px;
}
.boxes .box-list-row ul li h5.title-txt {
    margin-top: 10px;
    height: 25px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}
.boxes .box-list-row ul li .box-type {
    font-family: 'Avenir Next Demi';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 1.3;
    letter-spacing: 0.315324px;
    color: #fff;
    margin-bottom: 0;
    text-align: center;
}

  /* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-window-appearance: textfield;
}

.rightWrap .boxes {
    padding-bottom: 150px;
}

/*****Message details*****/

.messageBoxDetails{
    padding: 15px;
    margin-bottom: 20px;
    position: relative;
    z-index: 1;
    background: rgba(97, 95, 95, 0.3);
    border-radius: 7px;
}

.messageBoxDetails .userDetailsBlock{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    padding-bottom: 10px;
    box-shadow: 0px 0.5px 0 0 #ffffff;
    flex-wrap: wrap;
}
.messageBoxDetails .title{
    color: #E79E1F;
    font-size: 18px;
}
.messageBoxDetails .actionsBtn{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}
.messageBoxDetails .labelTxt{
    font-size: 18px;
    color: #E79E1F;
    margin-right: 10px;
}
.messageBoxDetails .labelTxt span{ 
    color: #ffffff;
}
.messageBoxDetails .actionsBtn .schedule{
    height: 43px;
    padding:  0 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(62, 62, 62, 0.9);
    border-radius: 10px;
    font-size: 16px;
    line-height: 25px;
    color: #E79E1F;
    outline: none;
    border: 0;
    box-shadow: none;
    text-transform: capitalize;
    min-width: 100px;
    margin: 0 5px 3px;
}
.messageBoxDetails .actionsBtn .schedule:hover,
.messageBoxDetails .actionsBtn .schedule:focus {
    color: #ffffff;
    background: #E79E1F;
}
.messageBoxDetails .actionsBtn .edit{
    margin:  0 5px 3px;
    color: #E79E1F;
    border-color: #E79E1F;
}
.messageBoxDetails .actionsBtn .edit:hover{
    background-color: #E79E1F;
    color: #ffffff;
}
.messageBoxDetails .actionsBtn .delete{
    margin: 0 5px 3px;
}
.messageBoxDetails .messageTextView p{
    color: #FFFFFF;
    font-size: 18px;
    margin-bottom: 15px;
}
.docsAttach{
    display: flex;
    flex-flow: column;
}
.docsAttach img{
    height: auto;
    width: 90%;
    margin: 0 auto;
    display: block;
}
.docsAttach .text-warning{
    text-align: center;
    display: block;
    margin: 10px 0;
}
.docsAttach .deleteBtn{
    width: 80%;
    margin: auto auto 0;
    display: block;
}
.imgUploaded {
    display: flex;
    flex-flow: column;
}
.imgUploaded img{
    margin: 0 auto;
    display: block;
}
.imgUploaded .text-warning{
    text-align: center;
    display: block;
    margin: 10px 0;
}
.imgUploaded .deleteBtn{
    width: 87%;
    margin: auto auto 0;
    display: block;
}
.recipient-section table{
    width: 100%;
}
.recipient-section table th{
    font-size: 15px;
    font-weight: 600;
    color: #000;
    background: rgba(256, 256, 256, 0.7);
    padding: 5px 10px;
}
.recipient-section table td{
    border: 0;
    vertical-align: middle;
}
.currentPlanStatus{
    height: 100%;
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: flex-end;
}
.backBtn.btn:active{
    background: rgba(231, 158, 31, 0.8) !important;
}

.uploadModal  .file-upload-message {
    margin-top: auto;
    margin-left: auto;
    margin-right: auto;
    padding: 3px 5px;
}
.customInput{
    visibility: hidden;
    height: 0;
    width: 0;
    padding: 0;
}
.customeFileInput{
    font-size: 16px;
    width: 95px;
    cursor: pointer;
    background: #e9ecef;
    padding: 0.375rem 0.75rem;
    margin: 0 auto;
    display: block;
    text-align: center;
    border-radius: 7px;
}
.docBox .messageBoxDetails{
    /* width: 400px; */
    width: 100%;
}

.spaceAcquired .progress{
    height: 0.7rem;
}
.spaceAcquired .progress .progress-bar{
    background-color: #E79E1F;
}
.boxes.box-details .docBox{
    display: flex;
    flex-wrap: wrap;
}
.boxes.box-details .docBox .rowBox{
    margin-right: 20px;
}
.boxes.box-details .docBox .rowBox video,
.boxes.box-details .docBox .rowBox img,
.boxes.box-details .docBox .rowBox audio{
    width: 350px !important;
}

.boxes.box-details .docBox .rowBox.msgWrap {
    width: 100%;
    flex-shrink: 0;
}
.boxes.box-details .docBox .rowBox.msgWrap .rowWrap{
    display: block;
}

.welcome .plans .planList{
    display: flex;
}
.welcome .plans .planList .listItem{
    overflow: hidden;
    height: auto;
    width: calc(100% / 3);
}
.planListSpace{
    padding-top: 220px;
}
.plans .planList .planWraper{
    width: calc(100% / 3);
}

.footerSocial{
    margin-top: -4px;
}

.navbar-brand img{
    width: 95px;
}


.plans .planList .rate .planRateCol{
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    padding-bottom: 10px;
}
.plans .planList .rate .planRateCol .rightTxt{
    font-size: 22px;
    width: 85px;
    flex-shrink: 0;
    text-align: left;
}
.plans .planList .rate .planRateCol .rightTxt b{
    font-weight: normal;
}
.plans .planList .rate .planRateCol label{
    font-size: 22px;
    text-align: left;
}
.plans .planList .listHead p span{
    font-weight: 800;
    font-size: 25px;
    color: #f7a817;
}

.image-box-fix .image-item{
    object-fit: contain;
    object-position: center;
}
.image-in-modal{
    object-fit: contain;
    object-position: center;
}
.form-landing{
    background-color:#020620;
    border-radius: 40px;
    padding: 15px 15px;
    max-width: 20rem;
    text-align: center;
    margin: 0 auto;
}


/* New Home page css */
.theme-color {
    /* color: rgba(249, 166, 24, 0.83); */
    color: #f7a817;
}
.landingPage .intro .half-section {
    flex-basis: 50%;
    padding: 20px;
}
.heading-title {
    font-size: 55px;
    margin-bottom: 30px;
    color: white;
    font-family: 'Avenir Next Regular';
}
.heading-sub-title {
    font-size: 45px;
    margin-bottom: 15px !important;
    color: white;
    font-family: 'Avenir Next Regular';
    line-height: 1.5;
}
.font-regular {
    font-size: 16px !important;
}
.img-Cover{
    min-height: calc(100vh - 72px);
    height: 100%;
    flex-basis: 50%;
}
.img-Cover img{
    object-fit: cover;
    min-height: calc(100vh - 72px);
    height: 100%;
    width: 100%;
    object-position: top center;
}
.hero-content-para-cover {
    padding: 0 5%;
    
}
.hero-content-para-cover h5{
   font-size: 21px;
   font-family: 'Avenir Next Demi';
   margin-bottom: 2px;
}
.hero-content-para-cover p{
   font-size: 19px;
   font-family: 'Avenir Next Regular';
}
.center-content {
    max-width: 610px;
    margin: auto;
}
.center-content.foundation-content{
    max-width: 90%;
}
.checkBefore {
    padding-left: 70px;
    position: relative;
    font-family: 'Avenir Next Regular' !important;
}
.checkBefore:before {
    content:"";
    background-image: url('../images/check.png');
    width: 50px;
    height: 50px;
    display: block;
    position: absolute;
    background-size: contain;
    background-repeat: no-repeat;
    left: 0;
    top: 5px;
}
.checkBefore-modal {
    padding-left: 70px;
    position: relative;
    font-family: 'Avenir Next Regular' !important;
}
.checkBefore-modal:before {
    content:"";
    background-image: url('../images/check.png');
    width: 50px;
    height: 50px;
    display: block;
    position: absolute;
    background-size: contain;
    background-repeat: no-repeat;
    left: 0;
    top: 5px;
}
.appstore {
    margin-right: 20px;
}

.container-width {
    width: 88%;
    margin: auto;
}
.container-width-80 {
    width: 80%;
    max-width: 1600px;
    margin: auto;
}
.gift .btnBlock img {
    max-width: 250px;
}

.button-secondary {
    background-color: #1142bf; 
    border: none;
    color: white;
    padding: 10px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 26px;
    font-family: 'Avenir Next Demi';
  }
  .button-secondary:hover{
    color: #fff;
  }
  .btn-large{
    min-width: 350px;
    font-family: 'Avenir Next Regular';
    padding: 5px 25px
  }
.card-blog {
    background-color: transparent;
}
.card-blog .card-body {
    padding: 10px 0;
}
.card-blog  img {
    border-radius: 20px;
    width: 80%;
    margin: 0 auto 30px;
}
.card-blog  p {
    color: #fff;
    font-size: 20px;
}
.img-fluid-80{
    width: 70%;
}
.break-spaces {
    max-width: 100%;
    white-space: break-spaces;
    word-break: break-all;
}
.bg-Main-Content {
    background: #031030;
    padding: 40px;
    border-radius: 50px;
}
.bg-Main-Content p{
    font-size: 18px;
    font-family: 'Avenir Next Regular';
}
.pl-50 {
    padding-left: 50px;
}
.blog-img-cover img{
    max-height: 220px;
    object-fit: cover;
    width: 100%;
}
.view-post-link {
    text-decoration: underline !important;
    font-size: 20px;
}
.py-container {
    padding-top: 100px;
}
.foundation-content p {
    font-size: 16px;
    line-height: 1.3;
}
.blog-detail-image {
    max-height: 500px;
    object-fit: cover;
    width: 100%;
}
.blog-title {
    line-height: 1.3;
   
}
.social-sahre ul  {
    display: flex;
    padding: 0;
}  
.social-sahre ul li  {
    margin-right: 10px;
}  
.social-sahre img {
    max-width: 30px;
}
.imageInput.disabled {
    pointer-events: none; 
    position: relative;
}

/*new css*/
.block-cell {
    padding: 5px;
}
.currency {
    font-weight: bold;
    margin-left: 5px;
}

.form-landing .form-group > label{
    font-size: 0;
    display: block;
    margin-top: 15px;
}

.blog-container p,
.blog-container .heading-title,
.blog-container h2,
.blog-container h3{
    font-family: 'helvetica_worldregular';
    font-weight: 400;
}
.blog-container h5,
.blog-container b,
.blog-container strong{
    font-family: 'helvetica_worldbold';
}
.blog-container .theme-color{
    color: #f7a817;
}
.blog-container .py-container{
    padding-top: 60px;
}
.blog-container h3{
    line-height: 1.2;
}
.blog-container .bg-Main-Content{
    padding: 30px 35px;
    border-radius: 40px;
}
.blog-container .blog-img-cover img{
    border-radius: 15px;
}
.pl-35 {
    padding-left: 35px;
}

.pagination > li > .page-link,
.pagination > li > span {
    margin: 0 4px;
    border-radius: 4px;
    font-size: 15px;
    color: #263b71;
    font-weight: 700;
    padding: 7px 13px;
    background-color: #ffffff;
    border-color: #263b71;
    box-shadow: none;
}
.pagination > li:hover .page-link,
.pagination > li:focus .page-link,
.pagination > li.active .page-link {
    background-color: #0d6efd;
    border-color: #0d6efd;
    color: #fff;
    box-shadow: none;
}
.pagination > li.previous a,
.pagination > li.next a {
    background-size: 10px;
    background-position: center;
    background-repeat: no-repeat;
    display: block;
    padding: 5px 11px;
}
.pagination > li a:focus {
    outline: 0;
}

.carousel-slider .card-blog img{
    border-radius: 50%;
}
.carousel-slider .carousel-control-prev,
.carousel-slider .carousel-control-next{
    align-items: flex-start;
    width: 35px;
    opacity: 1;
}
.carousel-slider .carousel-control-prev{
    left: -10px;
}
.carousel-slider .carousel-control-next{
    right: -10px;
}
.carousel-slider .carousel-control-prev span,
.carousel-slider .carousel-control-next span{
    top: 40%;
    position: relative;
    background: none;
    height: 35px;
}
.carousel-slider .carousel-control-prev span{
    transform: rotate(180deg);
}
.carousel-slider .carousel-control-prev span:before,
.carousel-slider .carousel-control-next span:after{
    content: " ";
    border-right: 3px solid #f7a817;
    border-top: 3px solid #f7a817;
    width: 20px;
    height: 20px;
    transform: rotate(45deg);
    cursor: pointer;
    display: block;
    position: absolute;
    top: 8px;
}
.adminModal .modal-body .btn-primary{
    margin-top: 10px;
}
.addBlogModal .modal-body .row > .col-md-auto:last-child{
    width: 100%;
}
.hidden {
    display: none;
}
.form-landing .form-control,
.form-landing  .form-control:focus{
    background-color: #031030 !important;
    border: 1px solid #123ea9 !important;
    color: #fff;
}
.form-landing  .form-control::placeholder{
    color: #fff;
}
.form-landing  .form-control::-ms-input-placeholder{
    color: #fff;
}
.helvetica-world-bold{
    font-family: "helvetica_worldbold", sans-serif;
}
.mobile-card-box h4 {
    font-size: 24px;
}
.scrollData{
    height: 50vh;
    overflow: auto;
}
.main-chk{
    justify-content:space-between;
    align-items: flex-end;

}
.m-3.h48{
height: 48px;
}
.col-3.h62{
    height: 62px;
}
.mt-85{
    margin-top: 85px;
}
.modal-font{
    font-size: 40px;
}
.visionWrap .card-blog img{
    border-radius: 0px;
    margin:0px auto 20px;
}
.listBody {
    padding: 0px 20px 10px;
}

.listBody p {
    margin-bottom: 0px;
}
.listBody p:first-child{
   font-weight:900 
}
.listBody p:last-child{
    color:#f7a817;
    font-weight:800;
    flex: 0 0 150px;
    text-align: right;
    word-break: break-word;
}
.contactForm .formRow .text-danger:first-letter {
    text-transform: capitalize;
}


.modalDownloadApp .modal-dialog{
    max-width: 980px;
    width: 90%;
}
.modalDownloadApp .modal-body{
    padding: 50px;
}
.modalDownloadApp .modal-body p{
    font-size: 30px;
    line-height: 1.4;
    color: #ffffff;
    text-align: center;
}

.modalDownloadApp .modal-body p a{
    text-decoration: underline !important;
}