@media (max-width: 1600px){
    .inner-page-container header {
        left: 130px;
        width: calc(100vw - 160px);
        padding: 49px 0 0;
    }
    .rightWrap {
        padding-left: 142px;
        padding-right: 20px;
    }
}
@media (min-width: 1400px){
    .container, .container-lg, .container-md, .container-sm, .container-xl, .container-xxl {
        max-width: 1370px;
        position: relative;
    }
    header .plan-dtl-row {
        top: -35px;
    }
    .app-img {
        max-width: 70%;
        margin: auto;
      }
    .blog-container .bg-img h2{
        font-size: 50px;
    }
}
@media (max-width: 1440px){
    .box-records-row .box_records{
        width: 360px;
      }
}
@media (max-width: 1390px){
    /* header .searchBlock {
        width: 345px;
    } */
    .nav-link{
        /* margin-left: 15px; */
        margin-right: 20px;
    }
    .createAccount h2 {
        margin-bottom: 30px;
    }
    .createAccount form .submitBtn {
        margin: 36px auto 0;
    }
    .subscribe{
        flex-wrap: wrap;
    }
    .subscribe p {
        margin-bottom: 15px;
    }
    .box-records-row .box_records{
        width: 320px;
      }
      .heading-title {
        font-size: 50px;
    
    }
}
@media screen and (min-width: 1300px) {
    .boxes .box-list-row ul li {
        max-width: calc(100% / 5 - 30px);
        padding-right: 35px;
    }
    
    .boxes .box-list-row ul li .image img{
        width: auto !important;
        max-width: inherit;
    }
}
@media screen and (min-width: 1300px) and (max-width: 1500px) {
    .boxes .boxList .image {
        /* height: 150px; */
        width: 100%;
    }
}
@media screen and (min-width: 1200px) {
    .leftWrap:hover {
        width: 400px;
    }
    .leftWrap .navigation li.addBtn {
        padding-left: 96px;
    }
}
@media screen and (max-width: 1280px) and (max-height: 800px){
    .landingPage .intro .content{
        margin-top: 60px;
    }
}
@media screen and (max-width: 1280px) and (max-height: 610px){
    header{
        padding: 9px 32px;
    }
    .landingPage .intro .content{
        margin-top: 70px;
        padding: 27px 45px 45px;
    }
}
@media (max-width: 1199px){
    .gift img{
        margin-bottom: 20px;
    }
    header .plan-dtl-row {
        right: inherit;
        left: 50%;
        transform: translateX(-50%);
        top: 58px;
    }
    .navbar-collapse {
        /* background: #272D35; */
        background: #020829;
        margin-bottom: 20px;
        position: absolute;
        width: 100%;
        top: 75px;
        z-index: 99;
    }
    .header-inner-frame .navbar-collapse {
        top: 55px;
    }
    header .navbar-collapse::before {
        content:"";
        display: block;
        width: 0; 
        height: 0; 
        border-left: 15px solid transparent;
        border-right: 15px solid transparent;
        border-bottom: 15px solid #020829;
        position: absolute;
        top: -11px;
        right: 10px;
    }
    header .navLeft{
        width: 100%;
        flex: 100%;
        justify-content: center;
    }
    .inner-page-container header .navLeft {
        flex: 100%;
    }
    .inner-page-container header .searchBlock {
        margin-left: 0;
    } 
    .landing-page-container header .searchBlock {
        margin-left: 0;   
    }
    header .searchBlock {
        width: 100%;
    }
    .inner-page-container .innerpage {
        padding-top: 20px;
    }
    /** LEft Wrap  **/
    .leftWrap:hover {
        width: 310px;
        background: #000000;
    }
    
    .leftWrap .app-logo-frame {
        display: block;
        width: 120px;
        margin: 0 auto;
    }
    .leftWrap .navigation{
        padding: 10px 10px 0;
    }
    .boxes .boxList li{
        width: 217px;
    }
    .leftWrap .navigation li {
        margin-bottom: 4vmin;
    }
    .leftWrap .navigation li.addBtn{
        background-size: 40px 40px;
        padding: 10px 0 10px 65px;
        margin-left: 17px;
    }
    .leftWrap .navigation li.myboxes,
    .leftWrap .navigation li.plans,
    .leftWrap .navigation li.terms {
        padding: 7px 0 7px 80px;
        background-position: 22px center;
    }
    .leftWrap .navigation li.logout{
        padding: 7px 0 7px 80px;
        background-position: 20px center;
    }
    .leftWrap .navigation li.terms{
        background-position: 23px center;
    }
    .leftWrap .navigation li.profile .profileImg{
        margin-right: 23px;
        margin-left: 8px;
    }
    .leftWrap .navigation li.profile {
        padding: 0 0 0 9px
    }
    .leftWrap .navigation li.password {
        padding-left: 80px;
        background-position-x: 16px;
    }
    .leftWrap .navigation li.people{
        padding-left: 80px;
        background-position-x: 16px;
    }
    .leftWrap .navigation li.recyclebinEmpty, 
    .leftWrap .navigation li.recyclebinFull{
        padding-left: 80px;
        background-position-x: 17px;
    }
    .leftWrap .navigation li a{
        font-size: 18px;
    }
    /* Intro Content */
    .landingPage .intro .content {
        margin-top: 50px;
    }
    .regBtn{
        margin-left: 15px;
    }
    .landingPage .intro{
        height: auto;
        /* padding: 200px 0 150px; */
    }
    .gift {
        padding: 90px 0 180px;
    }
    .howItWorks h2 {
        margin-bottom: 90px;
    }
    .download .image {
        margin-top: -130px;
        margin-bottom: -160px;
    }
    .navbar-toggler{
        margin-left: auto;
        /* margin-bottom: 20px; */
    }
    .boxes .boxListFolder{
        padding: 0 5% 65px;
    }
    .plans .planList {
        grid-template-columns: auto auto;
        grid-gap: 50px 50px;
    }
    .discoverBoxes .discoverBoxesList{
        grid-template-columns: auto auto auto;
    }
    header .searchBlock .dropdown .dropdown-menu .recentBox {
        grid-gap: 0 15px;
    }
    .box-records-row .box_records{
        width: 360px;
    }
    .floatingBtnBlock{
        right: 30px;
        top: 140px;
    }
    .boxes .boxListFolder .userDetails{
        margin: 0 auto 50px;
    }
    .createAccount.changePassword .userDetailBlock{
        padding-left: 0;
        padding-right: 0;
    }
    .createAccount.changePassword .formWrap{
        padding: 60px 12%;
    }
    .checkBefore {
        padding-left: 0;
    }
    .wm-100{
        width: 100% !important;
    }
}
@media screen and (min-width: 991px){
    .createAccount .formWrap{
        min-height: 84vh;
        width: 100%;
        padding: 60px 23%;
        overflow-y: auto;
        overflow-x: hidden;
    }
    .para-heart-img{
        padding: 0 80px 0 40px;
    }
    .adminModal .modal-body #multi-dropdown-basic{
        margin-top: 50px;
    }
}
@media screen and (max-width: 991px){
    .fliterBtn {
        right: 10px;
        top: 150px;
    }
    .floatingBtnBlock{
        right: 0;
    }
    .createAccount .userDetailBlock {
        overflow: auto;
        height: auto;
        width: 65%;
        padding: 120px 20px;
        display: block;
    }
    .howItWorks .videoContainer:before{
        display: none;
    }
    .howItWorks .videoContainer{
        display: block;
        height: auto;
    }
    .howItWorks .videoBlock {
        margin: 0 auto 30px;
        max-width: 508px;
    }
    footer .footerContact {
        max-width: 100%;
        margin-bottom: 30px;
    }
    .download a, .download .googleplay {
        margin: 0 10px 10px;
        width: 160px;
    }
    .download h2{
        text-align: center;
    }
    .boxes .boxListFolder{
        border-radius: 110px 110px 0px 0px;
    }
    .boxes .boxListFolder ul{
        grid-template-columns: auto auto;
        justify-content: space-around;
    }
    .discoverBoxes .discoverBoxesList{
        grid-template-columns: auto auto;
    }
    
    .messageBoxDetails .userDetailsBlock{
        display: block;
    }
    .box-records-row .box-records-col{
        margin: 0 auto;
        width: 100%;
    }
    .box-records-row .box_records {
        width: 100%;
    }
    

}
@media screen and (min-width: 768px){
    .leftWrap:hover {
        /* background: #000000; */
        background: #0B1320;
    }
    .closeNav,
    .dotMenuBtn{
        display: none;
    } 
}
/*new css*/
@media only screen and (min-width: 992px) {
    .landingPage .intro.blog-home .half-section .center-content{
        max-width: 90%;
    }
}
@media (max-width: 767px){  
    /* .navbar .dotMenuBtn {
        display: none;
    } */
    .mt-85{
        margin-top: 0px;
    }
    .main-chk{
        flex-direction: column;
        
    }
    .main-chk .col-3{
        min-width:100%
    } 
    .col-3.h62,
    .m-3.h48{
        height: auto;
        margin-top: 0px !important;
        }

    .floatingBtnBlock{
        right: 0;
    }
    .uploadModal h4{
        margin-bottom: 10px;
    }
    .uploadModal .uploadItems h5{
        font-size: 16px;
    }
    .uploadModalIcons{
        height: 45px;
    }
    .file-upload-button{
        margin-top: 10px;
    }
    .boxes .boxListFolder .userDetails {
        margin: 0 auto 50px;
    }
    .modal-body {
        padding: 0 20px 34px;
    }
    .inner-page-container header {
        left: 0;
        width: 100%;
    }
    .inner-page-container .container {
        max-width: 100%;
    }
    header .plan-dtl-row {
        left: 0;
        top: 40px;
        transform: inherit;
        width: 100%;
        display: flex;
        justify-content: center;
    }
    .navbar-toggler {
        /* margin-top: 10px; */
        position: relative;
        top: -35px;
    }
    .landing-page-container .navbar-toggler{
        top: 0;
    }
    .navbar-toggler .navbar-toggler-icon {
        background-size: 145%;
    }
    .landing-page-container #navbarScroll {
        top: 60px;
    }
    #navbarScroll {
        /* width: 180px;
        margin-left: 290px; */
        top: 40px;
    }
    #navbarScroll::before {
        content: '';
        display: block;
        position: absolute;
        top: -14px;
        right: 17px;
        width: 0;
        height: 0;
        border-left: 11px solid transparent;
        border-right: 11px solid transparent;
        border-bottom: 15px solid #020829;
    }
    .inner-page-container .innerpage {
        padding-top: 60px;
    }
    .aboutUsMainContent{
        margin-right: 50px;
        margin-left: -70px;
    }
    .boxes{
        
        margin-right: 50px;
        margin-left: -70px;
    }
    .contentWrap{
        margin-right: 50px;
        margin-left: -70px;
    }
    .planContent{
        /* margin-right: 50px;
        margin-left: -70px; */

    }
    .tnc{
        margin-right: 50px;
        margin-left: -70px;

    }
    .myProfile{
        margin-right: 50px;
        margin-left: -70px;

    }
    /* .addNewRecipent Row{
       border: 20px solid green;
    } */
    .floatingBtnBlock{
        top: 150px;
    }
    header .searchBlock .searchIcon {
        right: 9px;
    }
    .regBtn {
        font-size: 16px;
        padding: 11px 10px 12px;
    }
    .landingPage .intro {
        /* padding: 250px 0 150px; */
    }
    .landingPage .intro .content p {
        font-size: 26px;
    }
    .landingPage .intro .content:before {
        left: 15px;
        right: 15px;
        width: auto;
    }
    .landingPage .intro .content {
        padding: 50px 25px 45px;
    }
    .gift {
        padding: 40px 0 100px;
    }
   
    .howItWorks {
        padding: 16px 0 40px;
        
    }    
    .download{
        padding-bottom: 100px;
    }
    .createAccount .userDetailBlock {
        overflow: auto;
        width: 100%;
        padding: 30px 20px;
        border-radius: 0;
        margin: 20px;
    }
    .subsForm .form-control {
        max-width: 100%;
        width: 100%;
    }
    .download .image {
        margin-top: 0;
        margin-bottom: 0;
    }
    footer{
        /* padding-bottom: 30px; */
        padding: 20px 20px 30px;
    }
    footer h4{
        margin-bottom: 25px;
    }
    footer ul{
        margin-bottom: 40px;
    }
    .subscribe p {
        margin-right: 0;
    }
    header, .innerpage header {
        padding: 20px 20px;
    }
    .rightWrap {
        /* padding-left: 125px; */
        padding-left: 0;
        padding-right: 0;
    }
    .leftWrap{
        width: 300px;
        overflow: hidden;
        background: #000000;
        padding: 20px 11px 40px;
        transform:translateX(-300px);
    }
    .leftWrap.showNav{
        overflow-y: scroll;
        transform:translateX(0);
    }
    .leftWrap .navbar-brand img{
        /* width: 78px; */
        height: 93px;
        max-width: initial;
    }
    
    .subsForm .subsBtn{
        position: static;
        margin-top: 12px;
    }
    .subsForm .form-control{
        padding: 12px;
    }
    .charity .charityList{
        display: block;
        border-radius: 40px;
        padding: 20px;
    }
    .charity .charityList .contentDesc{
        width: 100%;
    }
    .yellowBtn,
    .darkBtn{
        padding: 14px 18px 13px;
        font-size: 15px;
    }
    .createAccount .formWrap.h-100{
        height: auto !important;
    }
    .landingPage .intro .content a{
        font-size: 14px;
    }
    header .searchBlock .dropdown .dropdown-menu{
        display: none;
    }
    header .searchBlock .dropdown.show .searchInput {
        border-radius: 10px;
    }
    .editProfileModal .modal-content {
        border-radius: 20px;
    }
    .editProfileModal .profileImg .imgs {
        width: 110px;
        height: 110px;
    }
    .editProfileModal .modal-body {
        padding: 0 15px 30px;
    }

    /********************************************************* 
        CSS Elements - RP 
    **********************************/
    /* .inner-page-container    header {
        left: 120px;
        width: calc(100% - 120px);
    } */
    .landing-page-container .charity_publicBox {
        /* padding: 200px 0 80px; */
    }
    .landing-page-container .aboutUsMainContent,
    .landing-page-container .boxes,
    .landing-page-container .contentWrap,
    .landing-page-container .tnc {
        margin-right: 0;
        margin-left: 0;
    }
    .inner-page-container header .searchBlock {
        width: 100%;
        margin-left: 0;
    }

    .inner-page-container .innerpage .rightWrap {
        padding-left: 20px !important;
        padding-top: 120px !important;
    }
    .inner-page-container .innerpage .rightWrap > .container {
        max-width: inherit;
    }
    .inner-page-container .innerpage .rightWrap .myProfile,
    .inner-page-container .innerpage .rightWrap .boxes,
    .inner-page-container .aboutUsMainContent,
    .inner-page-container .contentWrap,
    .inner-page-container .tnc {
        margin-right: 0;
        margin-left: 0;
    }
    .openDiscoverBox .contentWrap {
        padding: 0 20px 40px;
    }
    .tnc .list ul {
        padding-left: 0;
    }
    .tnc .list ul li ul {
        padding-left: 10px;
    }
    .tnc .list ul li span {
        margin-left: 10px;
    }
    .inner-page-container .rightWrap .myProfile .row .col {
        flex: 100%;
    }

    footer .footer-main-row {
        padding-left: 0 !important;
    }

    .messageBoxDetails .title{
        padding-top: 10px;
        margin-bottom: 20px;
    }
    .createAccount.changePassword .formWrap{
        padding: 60px 15px;
        width: 100%;
    }
}

@media screen and (min-width: 576px){
    .buyModal .modal-dialog{
        width: 562px;
    }
    .uploadModal .modal-dialog{
        width: 514px;
    }
    .ScheduleModal .modal-dialog{
        width: 428px;
    }
    .editProfileModal .modal-dialog{
        width: 588px;
    }
   
}
@media screen and (max-width: 670px){   
    .plans .planList {
        grid-template-columns: auto;
        grid-gap: 70px 0;
    }
}
@media screen and (max-width: 575px){
    
    header .plan-dtl-row {
        top: 20px;
    }
    header .plan-dtl-row .plan-dtl-lbl {
        flex-direction: column;
    }
    .boxes .boxListFolder ul{
        grid-template-columns: auto;
        justify-content: center;
    }
    .discoverBoxes .discoverBoxesList{
        grid-template-columns: auto;
    }
    .uploadModal .uploadItems{
        grid-gap: 18px;
    }
    .yellowBtn,
    .darkBtn{
        padding: 14px 18px 13px;
        font-size: 13px;
    }
    .btn-large{
        min-width: 300px;
    }
    .inner-page-container .innerpage .rightWrap {
        padding-left: 0 !important;
        padding-right: 0 !important
    }
    .createAccount .userDetailBlock {
        padding: 30px 15px;
        margin: 20px 0;
    }
    .createAccount form .form-control {
        font-size: 18px;
    }
    .docsAttach img {
        width: 60%;
        margin: 0 auto;
    }
}
@media screen and (max-width: 460px){
    header, .innerpage header {
        padding: 15px 10px;
    }
    .boxes .boxList ul{
        justify-content: center;
    }
    .leftWrap .navigation li.addBtn {
        /* padding-left: 72px; */
    }
    .img-Cover, .img-Cover img{
        margin-bottom: 0 !important;
    }
    .landingPage .intro .half-section{
        padding: 10px;
    }
    .landingPage .intro{
        min-height: calc(100vh - 85px);
    }
}

@media screen and (max-height: 700px) {
    .leftWrap .navigation {
        height: calc(100vh - 120px);
    }
    .leftWrap .navigation li  {
        margin-bottom: 3.5vmin;
    }
   
} 

@media screen and (max-width: 1199px) {
    .radiusImg{
        border-radius: 50%;
    }
    .welcome .plans .planList,
    .plans .planList{
        flex-wrap: wrap;
    }
    .welcome .plans .planList .listItem,
    .plans .planList .planWraper {
        height: auto;
        width: calc(100% / 2 - 25px);
    }
    .landing-page-container #navbarScroll{
        text-align: center;
        /* min-height: 60vh; */
    }
    .landing-page-container #navbarScroll .nav-link{
        font-size: 28px;
        padding: 35px 0;
        margin: 0;
    }
}
@media screen and (max-width: 991px) {
    .welcome .plans .planList .listItem,
    .plans .planList .planWraper {
        height: auto;
        width: calc(100% / 1);
    }
  
}

@media (max-width: 991px){
    .howItWorks .videoContainer  iframe{
        min-width: 100%;
    }
}

@media (max-width:767px){
    .createBoxImg {
      max-width: 100px;
      height: 100px;
    }
}

@media (max-width: 767px){
    .publicMessage img{
        display: inline!important;
        margin: 0 5px!important;
        vertical-align: 0px!important;
    }
    .plx-0 {
        padding-left: 0 !important;
    }
    .mtx-15 {
        margin-top: 15px
    }
    .container-width-80 {
        width: 90%;
    }
    .button-secondary {
        font-size: 22px;
    }
}


/* new pages media query */

@media screen and (min-width: 1200px) {
    .hero-content-para-cover{
        width: 90%;
    }
    .form-landing {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        bottom: 5%;
    }
    .card-blog  img {
        border-radius: 20px;
        width: 70%;
        margin: 0 auto 30px;
    }
    .less-width {
        padding: 2%;
    }
    .for-sm {
        display: none;
    }
}
@media screen and (min-width: 1200px) and (max-width: 1600px){
    .form-landing {
        left: 54%;
    }
}
@media screen and (max-width: 1290px){
    .heading-title {
        font-size: 50px;
    }
    .heading-sub-title  {
        font-size: 40px;
    }
}
@media screen and (max-width: 1199px) {
    header{
        padding-top: 5px;
        padding-bottom: 5px;
    }
    .landingPage .intro {
        flex-direction: column;
    }
    /* .landingPage .intro .img-Cover{
        position: absolute;
        opacity: 0.2;
    } */
    .checkBefore:before, .for-lg {
        display: none;
    }
    .img-Cover, .img-Cover img {
        min-height: 500px;
        max-height: 500px;
        width: 100%;
        margin-bottom: 15px;
    }
    .sm-covered-content .hero-content-para-cover, .sm-covered-content p  {
        background: #020620;
        text-align: center !important;
        padding: 25px;
        margin: 40px 20px;
        border-radius: 25px;
    }
    .hero-content-para-cover p{
        padding: 0;
        margin: 0;
    }
   
    .text-center-sm {
        text-align: center;
    }
    .navbar-toggler:hover,
    .navbar-toggler:focus,
    .navbar-toggler:active{
        background-color: transparent;
        border-color: transparent;
        box-shadow: none;
    }

    .navbar-toggler{
        display: block;
        width: 35px;
        height: 35px;
        padding: 0;
    }

    .navbar-toggler .navbar-toggler-icon{
        background-image: none;
        position: relative;
        display: block;
        width: 28px;
        height: 30px;
    }
    .navbar-toggler .navbar-toggler-icon:before,
    .navbar-toggler .navbar-toggler-icon:after{
        content: "";
        position: absolute;
        left: 0;
        top: -9px;
    }
    .navbar-toggler .navbar-toggler-icon,
    .navbar-toggler .navbar-toggler-icon:after,
    .navbar-toggler .navbar-toggler-icon:before {
        width: 100%;
        height: 4px;
        background-color: #fff;
        transition: all 0.3s;
        backface-visibility: hidden;
    }
    .navbar-toggler .navbar-toggler-icon:before{
        top: 9px
    }
    .navbar-toggler:not(.collapsed) .navbar-toggler-icon{
        background-color: transparent;
    }
    .navbar-toggler:not(.collapsed) .navbar-toggler-icon:before {
        transform: rotate(45deg) translate(-7px, -8px)
    }
    .navbar-toggler:not(.collapsed) .navbar-toggler-icon:after {
        transform: rotate(-45deg) translate(-5px, 5px)
    }
    
}
@media screen and (max-width: 991px){
    .navbar-brand img{
        width: 160px;
    }
    .blog-details p,
    .mobile-card-box{
        background: #031030;
        color: #fff;
        margin-bottom: 40px;
        border-radius: 30px;
        padding: 30px;
        font-size: 17px;
        line-height: 30px;
        text-align: center;
    }
    .blog-details p{
        text-align: left;
    }
    .mobile-card-box .theme-color{
        font-size: 25px;
        font-weight: 600;
    }
    .mobile-card-box p{
        text-align: center;
        font-size: 20px;
        line-height: 30px;
    }
    .mobile-card-box p:nth-of-type(1){
        margin-bottom: 0;
    }
    .mobile-card-box .blog-img-cover{
        margin-bottom: 20px;
    }
    .landingPage .bg-Main-Content,
    .aboutUsMainContent.text-white{
        /* background: none;
        padding: 0;
        border-radius: 0; */
    }
    .aboutUsMainContent .mobile-card-box,
    .blog-details p{
        /* background: linear-gradient(268.67deg, rgba(26, 32, 46, 0.7) 1.01%, rgba(26, 32, 46, 0.679) 78.02%, rgba(26, 32, 46, 0.455) 98.78%); */
    }
    .charity_publicBox{
        padding: 80px 15px;
    }
    .view-post-link{
        margin-top: 10px;
        display: inline-block;
    }
    .hero-content-para-cover{
        padding: 0;
    }
}
@media screen and (max-width: 767px){
    .text-warning-mobile {
        color: #f7a817 !important;
    }
    .img-Cover, .img-Cover img {
        min-height: 300px;
        max-height: 300px;
    }
    .center-content,
    .center-content.foundation-content{
        max-width: 92%;
    }
    .mobile-card-box .theme-color{
        font-size: 22px;
    }
    .carousel-slider .carousel-control-prev span,
    .carousel-slider .carousel-control-next span{
        top: 135px;
    }
    .sm-mw-100{
        max-width: 100% !important;
    }
    .openDiscoverBox_publicPage{
        padding: 0 10px;
    }
    .landing-page-container #navbarScroll .nav-link{
        padding: 25px 0;
    }
}

@media screen and (max-width: 580px) {
    .heading-title {
        font-size: 28px;
        margin-bottom: 10px;
        line-height: 1.2;
    }
    .heading-sub-title {
        font-size: 25px;
        margin-bottom: 10px !important;
    }
    .gift .btnBlock img {
        max-width: 180px;
    }
    .bg-Main-Content {
        padding: 30px 40px 30px;
    }
    .blog-title {
        font-size: 26px;
    }
    .iframe-youtube {
        height: 300px;
    }
    .howItWorks h2{
        margin-bottom: 20px;
    }
}
@media screen and (max-width: 440px) {
    .gift .btnBlock img {
        max-width: 145px;
    }
}

@media screen and (max-width: 375px) {
    .landing-banner, .landing-banner img{
        min-height: 160px;
        max-height: 160px;
    }
    .button-secondary{
        font-size: 18px;
    }

    .btn-large {
        min-width: 100%;
    }
}
@media screen and (max-width: 350px) {
    .heading-title{
        font-size: 20px;
    }
    .landing-banner, .landing-banner img{
        min-height: 140px;
        max-height: 140px;
    }
}
.nav-link-login {
    display: none; /* Hide the login link by default */
  }
  @media (max-width: 768px) {
    .nav-link-login {
      display: block; /* Display the login link for smaller screens */
    }
  }

  .width-50-web{
    width: 48%;
  }
  @media (max-width: 768px) {
    .width-50-web {
        width: 100%;
    }
  }


/* Default styles for all screen sizes */
.mobile-landing {
    display: none; /* Hide the form by default */
  }
  
  /* Media query for screens with a maximum width of 768px (e.g., tablets and mobile devices) */
  @media (max-width: 768px) {
    .mobile-landing {
      display: block; /* Display the form for smaller screens */
    }
    .web-landing{
        display: none;
    }
  }
  
  @media (max-width: 767px) {
    .tnc{
        padding: 15px;
    }
    .tnc ul{
        padding: 0 !important;
    }
    .tnc ul li ul{
        margin-left: 10px !important;
    }
  }
 @media (max-width: 991px){
    .carousel{
        height: 790px;
    }
  }

  @media (max-width: 767px){
    .carousel{
        height: 580px;
    }

    .aboutUsMainContent,
    .blog-details p, 
    .mobile-card-box{
        padding: 20px;
    }

    .landing-page-container .aboutUsMainContent > .mobile-card-box,
    .blog-container .blog-detail-image+.aboutUsMainContent {
        padding: 0;
    }

    .container-width {
        width: 90%;
    }

    .landingPage .landing-page-container .aboutUsMainContent,
    .landingPage .blog-container .bg-img .bg-Main-Content{
        padding-left: 0;
        padding-right: 0;
    }

    .mobile-card-box:last-child{
        margin-bottom: 0;
    }

    .foundationList,
    .landingPage .blog-container .intro .half-section .bg-Main-Content,
    .landingPage .intro .half-section .bg-Main-Content,
    .landingPage .aboutUsMainCOntent .bg-Main-Content{
        padding-left: 20px;
        padding-right: 20px;
        gap: 20px;
    }
    .hero-content-para-cover .mobile-card-box{
        margin-bottom: 45px;
    }

    .faqMainContent{
        padding: 20px;
    }

    .faqMainContent h2 {
        font-size: 28px;
        line-height: 1.2;
    }
}

@media (max-width: 767px) {
    .modalDownloadApp .modal-body p{
        font-size: 24px;
    }
}