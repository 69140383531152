@font-face {
    font-family: 'Avenir Next Regular';
    src: url('../fonts/AvenirNext-Regular.woff2') format('woff2'),
        url('../fonts/AvenirNext-Regular.woff') format('woff');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Avenir Next Medium';
    src: url('../fonts/AvenirNext-Medium.woff2') format('woff2'),
        url('../fonts/AvenirNext-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Avenir Next Demi';
    src: url('../fonts/AvenirNextCyr-Demi.woff2') format('woff2'),
        url('../fonts/AvenirNextCyr-Demi.woff') format('woff');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Avenir Next Bold';
    src: url('../fonts/AvenirNext-Bold.woff2') format('woff2'),
        url('../fonts/AvenirNext-Bold.woff') format('woff');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'helvetica_worldregular';
    src: url('../fonts/helveticaworld-regular-webfont.woff2') format('woff2'),
         url('../fonts/helveticaworld-regular-webfont.woff') format('woff');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'helvetica_worldbold';
    src: url('../fonts/helveticaworld-bold-webfont.woff2') format('woff2'),
         url('../fonts/helveticaworld-bold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}



